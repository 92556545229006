import { useDispatch } from "react-redux";
import ReactGA from "react-ga4";
import { addPatientAssignmentAction } from "store/actions/assignments";
import { toast } from "react-toastify";
import { useCurrentUser, useFetchAssignments, useLogout } from "utils/hooks";
import { useNavigate } from "react-router-dom";
import { Assignment } from "types";

const useAddAssignment = () => {
  const dispatch = useDispatch();
  const { logOut } = useLogout();
  const { user } = useCurrentUser();
  const { fetchAllAssignments } = useFetchAssignments();
  const history = useNavigate();

  interface UseAddAssignmentProps {
    args: Assignment;
    patientSlug?: string;
    origin: string;
  }

  const addAssignment = ({
    args,
    patientSlug,
    origin,
  }: UseAddAssignmentProps) => {
    if (!args) {
      return null;
    }
    return new Promise(() => {
      setTimeout(() => {
        fetch(`${process.env.REACT_APP_API_URL}`, {
          method: "POST",
          credentials: "include",
          body: JSON.stringify({
            query: `
            mutation {
              addAssignmentToPatientOrGroup(assignment: {
                type: "${args.type}",
                actionPlanId: "${args.actionPlanId}",
                patientId:  "${args.patientId}",
                daysOfWeek: "${args.notification.daysOfWeek}",
                notificationTime: "${args.notification.notificationTime}",
                notificationMessage: "${args.notification.notificationMessage}",
                startDate: "${args.notification.startDate}",
                endDate: "${args.notification.endDate}",
                status: "${args.notification.status}",
                templateUUID: "${args.templateUUID}",
              }) {
                  uuid
                  type
                  label
                  status
                  actionPlanId
                  patientId
                  slug
                  instructions
                  icon {
                    name
                    src
                  }
                  color
                  notification {
                    startDate
                    endDate
                    notificationTime
                    notificationMessage
                    daysOfWeek
                    status
                  }
                  sections {
                    uuid
                    name
                    assignmentId
                    createdAt
                    type
                    label
                    status
                    theme
                    slug
                    description
                    order
                    fields {
                      uuid
                      name
                      sectionId
                      type
                      label
                      status
                      placeholder
                      hint
                      defaultValue
                      min
                      max
                      choicesKey
                      required
                      config
                      inlineValue
                      videoURL
                      fileId
                      fileUrl
                      richText
                      fromPrevious
                      pinTitle
                      pinToSection
                      fromPreviousField
                      usePrevious
                      multiResponseOptions{
                        placeholder
                        }
                      options
                      moods
                      order
                    }    
                  }
              }
            }
          `,
          }),
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        })
          .then(async (res) => {
            if (res.status !== 200 && res.status !== 201) {
              const error = await res.json();
              if (error.errors[0].message.includes("BRKN_6001")) {
                toast.warn(`For security purposes please log in again.`);
                logOut();
              } else {
                toast.error(`Hmm, something went wrong.`);
                throw new Error("Failed!");
              }
            }
            return res.json();
          })
          .then((resData) => {
            if (resData.errors) {
              toast.error(
                `Hmm, something went wrong. ${resData.errors[0].message}`
              );
            } else {
              return dispatch(
                addPatientAssignmentAction(
                  resData.data.addAssignmentToPatientOrGroup[0]
                )
              );
            }
          })
          .then((res) => {
            if (res) {
              fetchAllAssignments();
              ReactGA.event("assignment_created_success", {
                user_id: user?.id,
                actionPlan_id: args.actionPlanId,
                patient_id: args.patientId,
                assignment_type: res.payload.label,
              });
              toast(`Great! A new ${res.payload.label} has been created!`);
              history(
                `/patients/${patientSlug}/${origin}?assignment-added=true&assignment=${res.payload.slug}`
              );
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }, 100);
    });
  };

  return {
    addAssignment,
  };
};

export default useAddAssignment;
