import React, { useState, useRef } from "react";
import { useReactToPrint } from "react-to-print";
import { toast } from "react-toastify";
import { InviteQRCode } from "types";
import { toAmericanDateWithTime } from "utils/helpers";
import { IconButton, Modal } from "components";
import { useCurrentUser, useGetTherapistProfile } from "utils/hooks";
import DeleteInviteCodeConfirmation from "../DeleteInviteCodeConfirmation";
import TrashIcon from "assets/icons/trash.svg";
import DownloadIcon from "assets/icons/download.svg";
import PrintIcon from "assets/icons/print.svg";
import LinkIcon from "assets/icons/link.svg";
import InviteCodeContent from "pages/InviteCodePages/InviteCodePage/components/InviteCodeContent";
import "./QRCode.styles.scss";

interface QRCodeProps {
  code: InviteQRCode;
}

const QRCode = ({ code }: QRCodeProps) => {
  const { user } = useCurrentUser();
  const { therapistProfile } = useGetTherapistProfile();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const { codeUrl, createdAt, uuid, slug } = code;

  const printRef = useRef<HTMLDivElement>(null);

  const shareLink = `${process.env.REACT_APP_URL}/invite-code/${user?.slug}/${slug}`;

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
  });

  const handleDownload = (url: string) => {
    const link = document.createElement("a");
    link.href = url;
    link.download = `${slug}.png`;
    link.download;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleLinkCopy = async (url: string) => {
    try {
      await navigator.clipboard.writeText(url);
      toast("Link copied to clipboard!");
    } catch {
      toast.error("Oops, failed to copy");
    }
  };

  let therapistCredentials = null;

  if (
    therapistProfile &&
    therapistProfile.profileCredentials &&
    therapistProfile.profileCredentials[0] &&
    therapistProfile.profileCredentials[0] !== "null"
  ) {
    therapistCredentials = therapistProfile.profileCredentials;
  }

  return (
    <>
      <div className="qrcode">
        <p>{toAmericanDateWithTime(createdAt)}</p>
        <div className="qrcode__content">
          <div className="qrcode__actions">
            <IconButton
              icon={TrashIcon}
              onClick={() => setIsDeleteModalOpen(true)}
            />
            <IconButton icon={PrintIcon} onClick={handlePrint} />
            <IconButton
              icon={DownloadIcon}
              onClick={() => handleDownload(codeUrl)}
            />
            <IconButton
              icon={LinkIcon}
              onClick={() => handleLinkCopy(shareLink)}
            />
          </div>
          <img src={codeUrl} alt="QR code" />
        </div>
      </div>
      <Modal
        isOpen={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
      >
        <DeleteInviteCodeConfirmation
          codeId={uuid}
          onCancel={() => setIsDeleteModalOpen(false)}
        />
      </Modal>
      <div style={{ display: "none" }}>
        <InviteCodeContent
          ref={printRef}
          inviteCodeInfo={{
            codeUrl,
            therapistEmail: (user && user?.email) || "",
            therapistFullName: user?.fullName as string,
            therapistCredentials,
          }}
        />
      </div>
    </>
  );
};

export default QRCode;
