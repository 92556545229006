import "./SingleGroup.styles.scss";
import React, { useState, useEffect } from "react";
import { Tabs, PageLoading, TopNav, SidePanel, Button, Icon } from "components";
import { useGetGroups, useFetchGroups } from "utils/hooks";
import { useParams, useNavigate } from "react-router-dom";
import GroupInfoCard from "./components/GroupInfoCard/GroupInfoCard";
import useSingleGroupTabs from "./utils/useSingleGroupTabs";
import GroupChooser from "./components/GroupChooser/GroupChooser";
import GroupActionsMenu from "../Groups/components/GroupActionsMenu";
import { Group } from "types";
import AddGroupsForm from "../Groups/components/AddGroupForm";
import EditIcon from "assets/icons/pen-to-square.svg";
import ArrowBack from "assets/icons/arrow-back.svg";
// import VideoIcon from "assets/icons/video.svg";

const SingleGroupPage: React.FC = () => {
  const { fetchGroups } = useFetchGroups();
  const { uuid } = useParams<{ uuid: string }>();
  const { groups } = useGetGroups({});

  const [currentGroup, setCurrentGroup] = useState<Group | null>(null);

  const navigate = useNavigate();

  const handleGroupChange = (groupId: string) => {
    const newGroup = groups.find((group: Group) => group?.uuid === groupId);
    if (newGroup) {
      setCurrentGroup(newGroup);
      navigate(`/groups/${newGroup?.slug}/${activeTab?.url}`);
    }
  };

  const { tabItems, activeTab, handleTabChange } = useSingleGroupTabs();

  useEffect(() => {
    fetchGroups();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (groups && groups.length > 0) {
      const group = groups.find((group: Group) => group?.slug === uuid);
      setCurrentGroup(group || null);
    }
  }, [groups, uuid]);

  const [isEditGroupVisible, setIsEditGroupVisible] = useState(false);

  const handleBackToGroups = () => {
    navigate(`/groups`);
  };

  if (!currentGroup) {
    return <PageLoading />;
  }

  return (
    <>
      <TopNav
        topL={
          <div
            className="single-group__back pointer"
            onClick={() => handleBackToGroups()}
            onKeyDown={() => handleBackToGroups()}
            role="button"
            tabIndex={0}
          >
            <Icon src={ArrowBack} />
            <div>
              <h3>Back to Groups</h3>
            </div>
          </div>
        }
        botL={
          <GroupChooser
            currentGroup={currentGroup}
            onChange={(groupId: { value: string }) =>
              handleGroupChange(groupId?.value)
            }
          />
        }
        botR={
          <div className="single-group-top-bar__actions">
            <div className="ml-1">
              <Button
                onClick={() => {
                  setIsEditGroupVisible(true);
                }}
                name={`edit_group`}
                value="Edit"
                extraClass="secondary"
                size="small"
                icon={EditIcon}
              />
            </div>
            <div className="flex align-center">
              {/* <Button
              onClick={() =>
                window.open(
                  `/session/${currentGroup.slug}`,
                  "_blank",
                  "rel=noopener noreferrer"
                )
              }
              name={`navigate_to_online_session_button`}
              value="Start Online Session"
              icon={VideoIcon}
              size="small"
              pill
            /> */}
              <GroupActionsMenu group={currentGroup} />
            </div>
          </div>
        }
      />
      <div className="single-group-top-area">
        <GroupInfoCard group={currentGroup} showName={false} />
        <div className="single-group-top-left">
          <div className="single-group-tabs-top">
            {activeTab?.actionComponent}
            <Tabs
              responsive
              items={tabItems}
              activeTab={activeTab}
              setActiveTab={handleTabChange}
              size={"large"}
            />
          </div>
        </div>
      </div>
      <div>
        <div className="single-group-tabs-body">{activeTab.mainComponent}</div>
      </div>
      <SidePanel
        title={`Edit ${currentGroup?.name}'s Profile`}
        isVisible={isEditGroupVisible}
        onClose={() => setIsEditGroupVisible(false)}
      >
        <AddGroupsForm
          onSuccess={() => setIsEditGroupVisible(false)}
          group={currentGroup}
        />
      </SidePanel>
    </>
  );
};

export default SingleGroupPage;
