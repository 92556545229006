import "./GroupsDropdown.styles.scss";
import React from "react";
import ReactSelect from "react-select";
import { useGetGroups } from "utils/hooks";
import {
  GroupOption,
  allGroupsOption,
  ValueContainer,
  MultiValue,
} from "./components/GroupOptions";
import { useTranslation } from "react-i18next";
interface GroupsDropdownProps {
  hint?: string;
  menuPlacement?: string;
  components?: any;
  isDisabled?: boolean;
  register?: any;
  name?: string;
  label?: string | null;
  placeholder?: string;
  defaultGroup?: {
    uuid: string;
    name: string;
  } | null;
  includeEmail?: boolean;
  rules?: any;
  onChange: (selected: any) => void;
  isMulti?: boolean;
  allowSelectAll?: boolean;
  closeMenuOnSelect?: boolean;
  hideSelectedOptions?: boolean;
  value?: any;
  isClearable?: boolean;
  styles?: any;
  className?: string;
  defaultToText?: boolean;
}

const GroupsDropdown = ({
  components,
  isDisabled,
  name,
  label = null,
  defaultGroup,
  includeEmail = true,
  onChange,
  isMulti = false,
  allowSelectAll = false,
  closeMenuOnSelect = true,
  hideSelectedOptions = true,
  value,
  isClearable = true,
  styles = {},
  className,
  defaultToText = false,
  placeholder,
  menuPlacement = "bottom",
}: GroupsDropdownProps) => {
  const { t } = useTranslation(["common"]);
  const { groups } = useGetGroups({});
  const currentPlaceholder = placeholder || t("confirmed_actions.sending");
  const mappedGroups = groups.map(
    (group: { uuid: any; name: any; lastName: any; email: any }) => {
      return {
        value: group.uuid,
        label: `${group.name} ${group.lastName}${
          includeEmail ? ` - ${group.email}` : ""
        }`,
      };
    }
  );

  const mappedCurrentGroup = {
    value: defaultGroup && defaultGroup.uuid,
    label: `${defaultGroup?.name}`,
  };

  const options = allowSelectAll
    ? [allGroupsOption, ...mappedGroups]
    : mappedGroups;

  if (defaultToText && groups.length === 1) {
    return <div className="default-to-text">{`${defaultGroup?.name}`}</div>;
  }

  return (
    <div className={`select ${className}`}>
      {label && <label className="select__label">{label}</label>}
      <ReactSelect
        value={value || defaultGroup}
        isClearable={isClearable}
        className={`select ${className}`}
        styles={styles}
        name={name}
        placeholder={currentPlaceholder}
        options={options}
        components={{
          Option: GroupOption,
          ValueContainer,
          MultiValue,
          ...components,
        }}
        defaultValue={
          defaultGroup || mappedCurrentGroup.value ? mappedCurrentGroup : null
        }
        isDisabled={isDisabled}
        noOptionsMessage={() => t("therapist_group_info.no_groups")}
        onChange={(
          selected: { value: string }[] | null,
          event: { action: string }
        ) => {
          if (selected !== null && selected.length > 0) {
            if (selected[selected.length - 1].value === allGroupsOption.value) {
              return onChange(options);
            }
            let result: { value: any; label: string }[] | { value: string }[] =
              [];
            if (selected.length === options.length - 1) {
              if (selected.includes(allGroupsOption)) {
                result = selected.filter(
                  (option: { value: string }) =>
                    option.value !== allGroupsOption.value
                );
              } else if (event.action === "select-option") {
                result = options;
              }
              return onChange(result);
            }
          }

          return onChange(selected);
        }}
        isMulti={isMulti}
        closeMenuOnSelect={closeMenuOnSelect}
        hideSelectedOptions={hideSelectedOptions}
        menuPlacement={menuPlacement}
      />
    </div>
  );
};

export default GroupsDropdown;
