import { RootStateOrAny, useSelector } from "react-redux";
import useGetPatients from "../patients/useGetPatients";
import useGetGroups from "../groups/useGetGroups";

type Props = {
  patientSlug?: string;
  groupSlug?: string;
  slug?: string;
  id?: string;
  email?: string;
  currentPatient?: Array<any>;
};

const useGetSubmissions = ({
  patientSlug,
  groupSlug,
  slug,
  id,
  email,
  currentPatient,
}: Props) => {
  const submissions = useSelector(
    (state: RootStateOrAny) => state.submissions && state.submissions
  );

  const { patient } = useGetPatients({ email: email, slug: patientSlug });
  const { specificGroup: group } = useGetGroups({ slug: groupSlug });

  if (!submissions) {
    return {
      submissions: [],
      submission: null,
      patientSubmissions: [],
    };
  }

  const formatSubmission = (submission: any) => {
    if (!submission) {
      return null;
    }

    const formattedSections = submission.responseFields.reduce(
      (acc: any, cur: any) => {
        if (!acc[cur.sectionId])
          acc[cur.sectionId] = {
            sectionLabel: cur.sectionLabel,
            sectionId: cur.sectionId,
            sectionOrder: cur.sectionOrder,
            responseFields: [],
          };
        acc[cur.sectionId].responseFields.push(cur);
        return acc;
      },
      {}
    );

    return {
      ...submission,
      sections: Object.values(formattedSections),
      isFlagged: submission.isNegative,
    };
  };

  const submission = submissions.submissions?.find(
    (submission: any) =>
      (submission?.slug === slug && slug) || (submission?.uuid === id && id)
  );

  const filterByPatient = currentPatient || patient;

  const patientSubmissions = submissions.submissions.filter(
    (submission: any) =>
      submission.userId === filterByPatient?.uuid &&
      submission.assignmentLabel !== "Safety Plan"
  );

  const groupSubmissions = submissions.submissions.filter(
    (submission: any) =>
      submission.userId === group?.uuid &&
      submission.assignmentLabel !== "Safety Plan"
  );

  return {
    submissions: submissions.submissions,
    submission: formatSubmission(submission),
    patientSubmissions: patientSubmissions,
    groupSubmissions,
  };
};

export default useGetSubmissions;
