import React, { useState, useEffect } from "react";
import SubmissionsIcon from "assets/icons/inbox.svg";
import GroupSubmissions from "../components/GroupSubmissions/GroupSubmissions";
import GroupLibrary from "../components/GroupLibrary/GroupLibrary";
import GroupWorkbook from "../components/GroupWorkbook/GroupWorkbook";
import GroupNotes from "../components/GroupNotes/GroupNotes";
import { useNavigate, useParams } from "react-router-dom";
import { TabItem } from "components/elements/Tabs/Tabs";

const useSingleGroupTabs = () => {
  const history = useNavigate();
  const tabItems: TabItem[] = [
    {
      name: "Inbox",
      url: "submissions",
      key: "submissions",
      icon: SubmissionsIcon,
      actionComponent: null,
      mainComponent: <GroupSubmissions />,
    },
    {
      name: "Workbook",
      url: "workbook",
      key: "workbook",
      icon: SubmissionsIcon,
      actionComponent: null,
      mainComponent: <GroupWorkbook />,
    },
    {
      name: "Notes",
      url: "notes",
      key: "notes",
      icon: SubmissionsIcon,
      actionComponent: null,
      mainComponent: <GroupNotes />,
    },
    {
      name: "Resources",
      url: "resources",
      key: "resources",
      icon: SubmissionsIcon,
      actionComponent: null,
      mainComponent: <GroupLibrary />,
    },
  ];

  const { uuid, slug } = useParams<{ slug: string; uuid: string }>();

  const getTabFromUrl = (): TabItem | undefined => {
    return tabItems.find((tab) => tab.url === slug);
  };

  const defaultTabIndex = tabItems[1];
  const defaultTab = getTabFromUrl() || defaultTabIndex;

  const [activeTab, setActiveTab] = useState<TabItem>(defaultTab);

  const handleTabChange = (tab: TabItem) => {
    history(`/groups/${uuid}/${tab?.url}`);
    setActiveTab(tab);
  };

  useEffect(() => {
    handleTabChange(defaultTab);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slug]);

  useEffect(() => {
    if (activeTab) {
      history(`/groups/${uuid}/${activeTab.url}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab, history, uuid]);

  return {
    tabItems,
    activeTab,
    handleTabChange,
  };
};

export default useSingleGroupTabs;
