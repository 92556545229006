import FacebookIcon from "assets/icons/resources/facebook.svg";
import FileIcon from "assets/icons/resources/file-heart.svg";
import InstagramIcon from "assets/icons/resources/instagram.svg";
import LinkIcon from "assets/icons/resources/link.svg";
import LinkedInIcon from "assets/icons/resources/linkedin.svg";
import PinterestIcon from "assets/icons/resources/pinterest.svg";
import RedditIcon from "assets/icons/resources/reddit-square.svg";
import SpotifyIcon from "assets/icons/resources/spotify.svg";
import VimeoIcon from "assets/icons/resources/vimeo.svg";
import YouTubeIcon from "assets/icons/resources/youtube.svg";

export const openInNewTab = (url: string) => {
  const newWindow = window.open(url, "_blank", "noopener,noreferrer");
  if (newWindow) newWindow.opener = null;
};

export const getUrlIcon = (url: string) => {
  if (!url) {
    return null;
  }

  if (url.includes("facebook.com") || url.includes("fb.me")) {
    return FacebookIcon;
  }

  if (url.includes("drive.google.com") || url.includes("docs.google.com")) {
    return FileIcon;
  }

  if (url.includes("instagram.com")) {
    return InstagramIcon;
  }

  if (url.includes("linkedin.com") || url.includes("lnkd.in")) {
    return LinkedInIcon;
  }

  if (url.includes("pinterest.com") || url.includes("pin.it")) {
    return PinterestIcon;
  }

  if (url.includes("reddit.com")) {
    return RedditIcon;
  }

  if (url.includes("spotify.com")) {
    return SpotifyIcon;
  }

  if (url.includes("vimeo.com")) {
    return VimeoIcon;
  }

  if (url.includes("youtube.com") || url.includes("youtu.be")) {
    return YouTubeIcon;
  }

  return LinkIcon;
};
