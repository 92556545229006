import "./DeleteResourceConfirmationModal.styles.scss";
import React from "react";
import { Icon, Button } from "components";
import WarningIcon from "assets/icons/exclamation-triangle.svg";
import { useDeleteLibraryResource } from "utils/hooks";

interface DeleteResourceConfirmationModalProps {
  resourceId: string | undefined;
  onCancel: () => void;
}

const DeleteResourceConfirmationModal = ({
  resourceId,
  onCancel,
}: DeleteResourceConfirmationModalProps) => {
  const { deleteLibraryResource } = useDeleteLibraryResource();

  return (
    <div className="resource-deletion-confirmation">
      <div className="resource-deletion-confirmation__icon">
        <Icon src={WarningIcon} alt="Warning Icon" />
      </div>
      <div className="resource-deletion-confirmation__title">
        Are you sure you want to delete this resource?
      </div>
      <div className="resource-deletion-confirmation__body">
        You won&apos;t be able to undo this action and your patients will no
        longer have access.
      </div>
      <div className="resource-deletion-confirmation__buttons">
        <Button
          onClick={onCancel}
          name={`cancel_delete_resource_button`}
          value="Never mind"
          extraClass="secondary-bland"
          size="small"
        />
        <Button
          onClick={() => {
            deleteLibraryResource(resourceId);
            onCancel();
          }}
          name={`confirm_delete_resource_button`}
          value="Yes, delete it"
          extraClass="danger"
          size="small"
        />
      </div>
    </div>
  );
};

export default DeleteResourceConfirmationModal;
