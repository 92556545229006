import "./AssignmentFields.styles.scss";
import React, { useState } from "react";
import { GeneratedField } from "components";
import AssignmentFieldsNavigation from "./AssignmentFieldsNavigation";
// import { ASSIGNMENT_ENTITY_STATUSES } from "utils/constants";

const AssignmentFields = ({ assignment }) => {
  const [currentSectionIndex, setCurrentSectionIndex] = useState(1);
  const sections = assignment?.sections || assignment;
  // const sections = rawSections?.filter(
  //   (rawSection) => rawSection.status === ASSIGNMENT_ENTITY_STATUSES.VISIBLE
  // );
  const sectionsLength = sections?.length;
  const nextDisabled = !(currentSectionIndex < sectionsLength);
  const previousDisabled = !(
    currentSectionIndex > 1 || currentSectionIndex === 0
  );
  const handleSectionChangeNext = () => {
    if (currentSectionIndex < sectionsLength) {
      setCurrentSectionIndex(currentSectionIndex + 1);
    }
  };
  const handleSectionChangeBack = () => {
    if (currentSectionIndex > 1 || currentSectionIndex === 0) {
      setCurrentSectionIndex(currentSectionIndex - 1);
    }
  };

  const currentSection = sections && sections[currentSectionIndex - 1];
  if (!currentSection || !assignment) {
    return null;
  }

  return (
    <>
      {sectionsLength > 1 && (
        <AssignmentFieldsNavigation
          onBack={handleSectionChangeBack}
          onNext={handleSectionChangeNext}
          nextDisabled={nextDisabled}
          previousDisabled={previousDisabled}
          sectionTitle={currentSection.label}
        />
      )}
      <div className="fields-box-wrapper">
        {currentSection.description && (
          <div className="light-text fields-box-description">
            {currentSection.description}
          </div>
        )}
        <div className="fields-box">
          {currentSection &&
            currentSection.fields
              .slice()
              .sort((a, b) => a.order - b.order)
              .map((field, i) => {
                return <GeneratedField key={i} field={field} readonly />;
              })}
        </div>
      </div>
    </>
  );
};

export default AssignmentFields;
