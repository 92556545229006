import { useState } from "react";

const useAssignmentDetailsTabs = () => {
  const assignmentDetailTabs = [
    {
      name: "Preview",
    },
    {
      name: "Settings",
    },
  ];
  const [activeTab, setActiveTab] = useState(assignmentDetailTabs[1]);

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  return {
    assignmentDetailTabs,
    activeTab,
    handleTabChange,
  };
};

export default useAssignmentDetailsTabs;
