export const fetchGroupsAction = (groups: any) => {
  return {
    type: "FETCH_GROUPS",
    payload: groups,
  };
};

export const addGroupAction = (t: any) => {
  return {
    type: "ADD_GROUP",
    payload: t,
  };
};

export const updateGroupAction = (t: any) => {
  return {
    type: "UPDATE_GROUP",
    payload: t,
  };
};

export const deleteGroupAction = (t: any) => {
  return {
    type: "DELETE_GROUP",
    payload: t,
  };
};
