import { useEffect } from "react";
import { RootStateOrAny, useSelector } from "react-redux";
import { useFetchInviteCodes } from "utils/hooks";
import { InviteQRCode } from "types";

const useGetInviteCodes = () => {
  const { fetchInviteCodes } = useFetchInviteCodes();

  const inviteCodes = useSelector(
    (state: RootStateOrAny) => state.inviteCodes && state.inviteCodes
  );

  useEffect(() => {
    if (inviteCodes?.inviteCodes?.length === 0) {
      fetchInviteCodes();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    inviteCodes: inviteCodes.inviteCodes.sort(
      (a: InviteQRCode, b: InviteQRCode) =>
        b.createdAt.localeCompare(a.createdAt)
    ),
  };
};

export default useGetInviteCodes;
