import { Icon } from "components";
import TrashIcon from "assets/icons/trash.svg";
import EditIcon from "assets/icons/pen-to-square.svg";
import DownloadIcon from "assets/icons/download.svg";
import React from "react";
import "./File.styles.scss";
import { getFileTypeIconFromUrl, openInNewTab } from "../../utils";
import ClampLines from "react-clamp-lines";
import { toAmericanDateWithTimeFromUnix } from "utils/helpers";
import { useViewport } from "utils/hooks";
interface FileCardProps {
  file: any;
  children?: React.ReactNode;
  handleFileEditClick?: (type: any) => void;
  handleFileDeleteClick?: (file: any) => void;
  patientHasFile?: boolean;
  patient?: any;
  isOwner?: boolean;
}

export const FileCard = ({
  file,
  patientHasFile,
  patient,
  handleFileEditClick,
  handleFileDeleteClick,
  isOwner,
  ...props
}: FileCardProps) => {
  const { fileUrl, title, description, updatedAt } = file;
  const { isMobile } = useViewport();
  const children = props.children;

  if (!fileUrl) {
    return null;
  }

  const onFileClick = (url: string) => {
    return () => openInNewTab(url);
  };

  return (
    <div className="file-card">
      <div className="file-card__inner">
        <div className="file-card-line">
          <div className="flex align-center">
            <div className="file-icon">
              <Icon src={getFileTypeIconFromUrl(fileUrl)} />
            </div>
            <div>
              <span className="file-card-title">
                {title && (
                  <div className="file-title">
                    <ClampLines
                      text={title}
                      id="file-clamped-title"
                      lines={2}
                      ellipsis="..."
                      className="file-title"
                      innerElement="h3"
                      buttons={false}
                    />
                  </div>
                )}
              </span>
              <p className="file-date">
                {toAmericanDateWithTimeFromUnix(updatedAt)}
              </p>
            </div>
          </div>
          {isOwner && (
            <div className="file-card__actions">
              <span
                className="file-card__actions-action"
                onClick={() =>
                  handleFileDeleteClick && handleFileDeleteClick(file)
                }
                onKeyDown={() =>
                  handleFileDeleteClick && handleFileDeleteClick(file)
                }
                role="button"
                tabIndex={0}
              >
                <Icon src={TrashIcon} />
              </span>
              <span
                className="file-card__actions-action"
                onClick={() => handleFileEditClick && handleFileEditClick(file)}
                onKeyDown={() =>
                  handleFileEditClick && handleFileEditClick(file)
                }
                role="button"
                tabIndex={0}
              >
                <Icon src={EditIcon} />
              </span>
              <span
                className="file-card__actions-action"
                onClick={onFileClick(fileUrl)}
                onKeyDown={onFileClick(fileUrl)}
                role="button"
                tabIndex={0}
              >
                <Icon src={DownloadIcon} />
              </span>
            </div>
          )}
        </div>
        {description && (
          <div className="file-description">
            <ClampLines
              text={description}
              id="file-clamped-description"
              lines={isMobile || !children ? 6 : 3}
              ellipsis="..."
              innerElement="p"
              stopPropagation={true}
              buttons={false}
            />
          </div>
        )}
        <div className="file-card-actions">
          {patientHasFile && (
            <p className="file-card-in-use">Shared with {patient?.firstName}</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default FileCard;
