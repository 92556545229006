export const fetchPublicLibraryAction = (resources) => {
  return {
    type: "FETCH_PUBLIC_LIBRARY",
    payload: resources,
  };
};

export const fetchTherapistLibraryAction = (resources) => {
  return {
    type: "FETCH_THERAPIST_LIBRARY",
    payload: resources,
  };
};

export const fetchPatientLibraryAction = (resources) => {
  return {
    type: "FETCH_PATIENT_LIBRARY",
    payload: resources,
  };
};

export const addLibraryResource = (resource) => {
  return {
    type: "ADD_THERAPIST_LIBRARY_RESOURCE",
    payload: resource,
  };
};

export const updateLibraryResource = (resource) => {
  return {
    type: "UPDATE_THERAPIST_LIBRARY_RESOURCE",
    payload: resource,
  };
};

export const deleteLibraryResourceAction = (resourceId) => {
  return {
    type: "DELETE_THERAPIST_LIBRARY_RESOURCE",
    payload: resourceId,
  };
};
