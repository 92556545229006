import "./SubmissionListItem.styles.scss";
import React from "react";
import { AssignmentIcon } from "components";
import { toAmericanDateWithTimeFromUnix } from "utils/helpers";
import { useGetPatients, useCurrentUser } from "utils/hooks";
import { useNavigate } from "react-router-dom";
import { AssignmentSubmission } from "types";

interface SubmissionListItemProps {
  submission: AssignmentSubmission;
  onClickHandler?: (submission: any) => void;
}

const SubmissionListItem = ({
  submission,
  onClickHandler,
}: SubmissionListItemProps) => {
  const { patient } = useGetPatients({ patientId: submission.userId });
  const { isPatient } = useCurrentUser();

  const baseUrl = isPatient ? "your-submissions" : "submissions";

  const history = useNavigate();
  const handleSubmissionItemNavigation = () => {
    history(`/${baseUrl}/${submission?.slug}`);
  };

  const clickHandler = onClickHandler
    ? () => onClickHandler(submission)
    : () => handleSubmissionItemNavigation();

  const isFullNameNullable =
    patient?.firstName === null && patient?.lastName === null;

  return (
    <div
      role="button"
      tabIndex={0}
      className="submissions-list-item"
      onClick={clickHandler}
      onKeyDown={clickHandler}
      aria-label="navigate to submission"
    >
      <div className="submissions-list-item_icon">
        <AssignmentIcon
          style={submission.assignmentLabel}
          variant="medium"
          color={submission.color}
          icon={submission.icon?.src}
        />
      </div>
      <div>
        <p className="submissions-list-item_title">
          <b>{submission.assignmentLabel}</b> -{" "}
          {toAmericanDateWithTimeFromUnix(submission.createdAt)}
        </p>
        <p className="submissions-list-item_author">
          {patient ? (
            <>
              {isFullNameNullable ? (
                patient?.email
              ) : (
                <>
                  {patient?.firstName} {patient?.lastName}
                </>
              )}
            </>
          ) : null}
        </p>
      </div>
    </div>
  );
};

export default SubmissionListItem;
