import { useState } from "react";
import ReactGA from "react-ga4";
import { updateLibraryResource } from "store/actions/library";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import {
  useFetchLibrary,
  useLogout,
  useCurrentUser,
  useGetTherapistLibrary,
} from "utils/hooks";
import { formatInput } from "utils/helpers";
import { formattedSharedWith } from "./libraryUtils";

const useUpdateLibraryResource = ({ resource, selectedPatients }) => {
  const [isUpdateSuccessful, setIsUpdateSuccessful] = useState(false);
  const dispatch = useDispatch();
  const { logOut } = useLogout();
  const { fetchTherapistLibrary } = useFetchLibrary();
  const { user } = useCurrentUser();
  const { therapistResources } = useGetTherapistLibrary({});

  const submitUpdateLibraryResource = (args) => {
    if (!args) {
      return null;
    }

    const filteredResources = therapistResources.filter(
      (therapistResource) => therapistResource.uuid !== resource.uuid
    );

    const existingUrl = filteredResources.find(
      (therapistResource) => therapistResource.resourceUrl === args.resourceUrl
    );

    if (existingUrl) {
      toast.warn(`A link with this url already exists in your library :)`);
      return null;
    }

    return new Promise((resolve) => {
      setTimeout(() => {
        fetch(`${process.env.REACT_APP_API_URL}`, {
          method: "POST",
          credentials: "include",
          body: JSON.stringify({
            query: `
              mutation {
                updateLibraryResource(resource: {
                  uuid: "${resource.uuid}",
                  resourceUrl: "${formatInput(args.resourceUrl)}",
                  title: "${formatInput(args.title)}",
                  description: "${formatInput(args.description)}",
                  imageUrl: "${formatInput(args.imageUrl)}",
                  sharedWith: [${formattedSharedWith(selectedPatients)}],
                }) {
                  resourceUrl
                  title
                  description
                  imageUrl
                  uuid
                  addedBy
                  updatedAt
                  sharedWith {
                    label
                    value
                  }
                }
              }
            `,
          }),
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        })
          .then(async (res) => {
            if (res.status !== 200 && res.status !== 201) {
              const error = await res.json();
              if (error.errors[0].message.includes("BRKN_6001")) {
                toast.warn(`For security purposes please log in again.`);
                logOut();
              } else {
                toast.error(
                  `${error.errors[0].message || "Hmm, something went wrong."}`
                );
                throw new Error("Failed!");
              }
            }
            return res.json();
          })
          .then((resData) => {
            if (resData.errors) {
              toast.error(
                `Hmm, something went wrong. ${resData.errors[0].message}`
              );
              resolve();
              return true;
            } else {
              fetchTherapistLibrary();
              toast("Resource Updated");
              setIsUpdateSuccessful(true);
              resolve();
              ReactGA.event("library_resource_added_success", {
                user_id: user?.id,
              });
              return dispatch(
                updateLibraryResource(resData.data.updateLibraryResource)
              );
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }, 100);
    });
  };

  return {
    submitUpdateLibraryResource,
    isUpdateSuccessful,
  };
};

export default useUpdateLibraryResource;
