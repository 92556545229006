import { useEffect } from "react";
import { RootStateOrAny, useSelector } from "react-redux";
import useGetPatients from "../patients/useGetPatients";
import useFetchLibrary from "./useFetchLibrary";
import useFetchFiles from "../files/useFetchFiles";
import useGetGroups from "../groups/useGetGroups";

interface UseGetTherapistLibraryProps {
  patientSlug?: string;
  withFiles?: boolean;
  groupSlug?: string;
}

const useGetTherapistLibrary = ({
  patientSlug,
  groupSlug,
  withFiles = false,
}: UseGetTherapistLibraryProps) => {
  const { fetchTherapistLibrary } = useFetchLibrary();
  const { fetchTherapistFiles } = useFetchFiles();

  const resources = useSelector(
    (state: RootStateOrAny) => state.resources && state.resources
  );
  const files = useSelector(
    (state: RootStateOrAny) => state.files && state.files
  );

  useEffect(() => {
    if (resources?.therapistResources?.length === 0) {
      fetchTherapistLibrary();
    }
    if (withFiles && patientSlug) {
      fetchTherapistFiles();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let patientLibrary = [];
  let patientFiles: any[] = [];
  const { patient } = useGetPatients({ slug: patientSlug });

  const filterLibraryByPatientId = (array: any[], string: any) => {
    const filteredArray = array.map((element) => {
      const hasPatient = element.sharedWith?.find(
        (item: { value: any }) => item.value === string
      );
      if (hasPatient) {
        return element;
      }
      return false;
    });

    return filteredArray;
  };

  const filterFilesByPatientId = (array: any[], string: any) => {
    const filteredArray = array.map((element: { sharedWith: any[] }) => {
      const hasPatient = element.sharedWith?.find(
        (item: { value: any }) => item.value === string
      );
      if (hasPatient) {
        return element;
      }
      return false;
    });

    return filteredArray;
  };

  if (patient && patient.uuid && resources?.therapistResources?.length) {
    patientLibrary = filterLibraryByPatientId(
      resources.therapistResources,
      patient.uuid
    );
  }

  if (patient && patient.uuid && files?.therapistFiles?.length) {
    patientFiles = filterFilesByPatientId(files.therapistFiles, patient.uuid);
  }

  let groupLibrary = [];
  let groupFiles: any[] = [];
  const { specificGroup: group } = useGetGroups({ slug: groupSlug });

  const filterLibraryByGroupId = (array: any[], string: any) => {
    const filteredArray = array.map((element) => {
      const hasGroup = element.sharedWith?.find(
        (item: { value: any }) => item.value === string
      );
      if (hasGroup) {
        return element;
      }
      return false;
    });

    return filteredArray;
  };

  const filterFilesByGroupId = (array: any[], string: any) => {
    const filteredArray = array.map((element: { sharedWith: any[] }) => {
      const hasGroup = element.sharedWith?.find(
        (item: { value: any }) => item.value === string
      );
      if (hasGroup) {
        return element;
      }
      return false;
    });

    return filteredArray;
  };

  if (group && group.uuid && resources?.therapistResources?.length) {
    groupLibrary = filterLibraryByGroupId(
      resources.therapistResources,
      group.uuid
    );
  }

  if (group && group.uuid && files?.therapistFiles?.length) {
    groupFiles = filterFilesByGroupId(files.therapistFiles, group.uuid);
  }

  return {
    therapistResources: resources?.therapistResources || [],
    patientLibrary: patientLibrary.filter((item) => item !== false),
    patientFiles: patientFiles.filter((file: boolean) => file !== false),
    groupLibrary: groupLibrary.filter((item) => item !== false),
    groupFiles: groupFiles.filter((file: boolean) => file !== false),
  };
};

export default useGetTherapistLibrary;
