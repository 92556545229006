import React, { useState } from "react";
import "./ReminderSettings.styles.scss";
import {
  Button,
  DateField,
  DaysOfWeekField,
  TimeField,
  Toggle,
  AlertBar,
  Input,
} from "components";
import ReminderAlertContent from "../../../ReminderAlertContent";
import useUpdateNotification from "utils/hooks/assignments/useUpdateNotification";
import { isValidDate } from "utils/helpers";

interface ReminderSettingProps {
  notification: any;
  groupFirstName: string;
  onChange: (values: any) => void;
  entityId: string;
}

const ReminderSettings = ({
  notification,
  groupFirstName,
  onChange,
  entityId,
}: ReminderSettingProps) => {
  const {
    daysOfWeek,
    notificationTime,
    startDate,
    endDate,
    status,
    notificationMessage,
  } = notification;
  const formattedNotificationTime = new Date(notificationTime);
  const formattedStartDate = new Date(startDate);
  const formattedEndDate = new Date(endDate);

  const [currentNotificationTime, setCurrentNotificationTime] = useState(
    formattedNotificationTime
  );
  const [currentDaysOfWeek, setCurrentDaysOfWeek] = useState(daysOfWeek);
  const [currentStartDate, setCurrentStartDate] = useState(formattedStartDate);
  const [currentEndDate, setCurrentEndDate] = useState(formattedEndDate);
  const [currentStatus, setCurrentStatus] = useState(status);
  const [currentNotificationMessage, setCurrentNotificationMessage] =
    useState(notificationMessage);

  const [errors, setErrors] = useState<any>({});
  const [timeInputCount, setTimeInputCount] = useState(1);

  const handleStatusChange = () => {
    const newStatus = currentStatus === "LIVE" ? "DISABLED" : "LIVE";
    setCurrentStatus(newStatus);
    handleValueChange({ newValue: newStatus, fieldName: "status" });
  };

  const isDisabled = currentStatus === "DISABLED";

  interface ValueChangeProps {
    newValue: string | number | string[] | number[] | Date;
    fieldName: string;
  }

  const handleValueChange = ({ newValue, fieldName }: ValueChangeProps) => {
    if (!entityId) {
      onChange({
        daysOfWeek: fieldName === "daysOfWeek" ? newValue : currentDaysOfWeek,
        notificationTime:
          fieldName === "notificationTime" ? newValue : currentNotificationTime,
        startDate: fieldName === "startDate" ? newValue : currentStartDate,
        endDate: fieldName === "endDate" ? newValue : currentEndDate,
        status: fieldName === "status" ? newValue : currentStatus,
        notificationMessage:
          fieldName === "notificationMessage"
            ? newValue
            : currentNotificationMessage,
      });
    }
    if (errors[fieldName]) {
      setErrors({ ...errors, [fieldName]: "" });
    }
  };

  interface ValidationErrors {
    startDate?: string;
    notificationTime?: string;
    notificationMessage?: string;
  }

  const validateFields = (): ValidationErrors => {
    const newErrors: ValidationErrors = {};
    if (!currentStartDate) {
      newErrors.startDate = "Start date is required";
    }
    if (!currentNotificationTime) {
      newErrors.notificationTime = "Notification time is required.";
    }
    if (!currentNotificationMessage) {
      newErrors.notificationMessage = "Notification message is required.";
    }
    return newErrors;
  };

  const { updateNotification } = useUpdateNotification();

  const handleSubmit = () => {
    const validationErrors = validateFields();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      updateNotification({
        entityId: entityId,
        daysOfWeek: currentDaysOfWeek,
        notificationTime: currentNotificationTime,
        startDate: currentStartDate,
        endDate: currentEndDate,
        status: currentStatus,
        notificationMessage: currentNotificationMessage,
      });
    }
  };

  const handleAddTimeInput = () => {
    setTimeInputCount(timeInputCount + 1);
  };

  const timeInputs = Array.from({ length: timeInputCount }, (_, index) => (
    <TimeField
      key={index}
      name="notification_time"
      placeholder="Daily Reminder Time"
      selected={currentNotificationTime}
      onChange={(value) => {
        setCurrentNotificationTime(value);
        handleValueChange({
          newValue: value,
          fieldName: "notificationTime",
        });
      }}
      disabled={isDisabled}
      error={errors.notificationTime}
    />
  ));

  return (
    <div>
      <div className="flex justify-between align-center mb-2">
        <h4 className="no-mt no-mb">Reminders</h4>
        <Toggle
          name="status"
          checked={currentStatus === "LIVE"}
          onChange={handleStatusChange}
        />
      </div>
      {isDisabled && (
        <div className="mt-3">
          <AlertBar
            content={<ReminderAlertContent isDisabled={isDisabled} />}
            type="info"
            rounded
            padded
          />
        </div>
      )}
      <div>
        <DateField
          name="start_date"
          dateFormat="MM/dd/yyyy"
          label="Start Date"
          placeholder="Start Date"
          selected={currentStartDate}
          onChange={(value: any) => {
            setCurrentStartDate(value);
            handleValueChange({
              newValue: value,
              fieldName: "startDate",
            });
          }}
          minDate={new Date()}
          hint={`When should ${
            groupFirstName
              ? `${groupFirstName} begin.`
              : "this assignment begin."
          }`}
          disabled={isDisabled}
          error={errors.startDate}
        />
        <DateField
          name="end_date"
          dateFormat="MM/dd/yyyy"
          label="End Date"
          placeholder="End Date"
          selected={isValidDate(currentEndDate)}
          onChange={(value: any) => {
            setCurrentEndDate(value);
            handleValueChange({
              newValue: value,
              fieldName: "endDate",
            });
          }}
          minDate={new Date()}
          hint={`Optional: When should ${
            groupFirstName
              ? `${groupFirstName} finish.`
              : "this assignment end."
          }`}
          disabled={isDisabled}
        />
        <h3 className="time-title">How often should this be done?</h3>
        {timeInputs}
        <button
          className="button_add-time-input"
          onClick={handleAddTimeInput}
          disabled={isDisabled}
          style={{ opacity: isDisabled ? 0.5 : 1 }}
        >
          Add another daily time
        </button>
        <DaysOfWeekField
          name="days_of_week"
          label="Every"
          placeholder="Daily Reminder Time"
          defaultValue={currentDaysOfWeek}
          onChange={(value) => {
            setCurrentDaysOfWeek(value);
            handleValueChange({
              newValue: value,
              fieldName: "daysOfWeek",
            });
          }}
          disabled={isDisabled}
          mustHaveOneOrMore
        />
        <Input
          extraClass="light"
          name="notification_message"
          type="text"
          label="Reminder Message"
          defaultValue={currentNotificationMessage}
          placeholder="E.g. Don't forget to fill in your thought record! You got this!"
          onChange={(e) => {
            setCurrentNotificationMessage(e.target.value);
            handleValueChange({
              newValue: e.target.value,
              fieldName: "notificationMessage",
            });
          }}
          error={errors.notificationMessage}
          disabled={isDisabled}
        />
        {entityId && (
          <Button
            onClick={handleSubmit}
            name={`save_assignment_notifications_button`}
            value="Save Reminder Settings"
            extraClass="save-settings"
            size="big"
          />
        )}
      </div>
    </div>
  );
};

export default ReminderSettings;
