import "./App.styles.scss";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BRAND_NAME, GA4 } from "utils/constants";
import React, { lazy, Suspense, useEffect } from "react";
import ReactGA from "react-ga4";
import { gatherUserAttributes } from "utils/chatbox";

import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
} from "react-router-dom";
import {
  AppLayout,
  AuthenticationLayout,
  GenericLayout,
  OnboardingLayout,
} from "layouts";
import { ConfettiCanvas, PageLoading } from "components";
import { useCurrentUser, useQueryParams } from "utils/hooks";
import { init } from "commandbar";

// Therapist Pages
import {
  AssignmentsBuilderPage,
  OnlineSession,
  PatientsPage,
  ResourcesPage,
  SinglePatientPage,
  SubmissionsPage,
  VideoSession,
  AssignmentSubmissionsPage,
  GroupsPage,
} from "./Routes/TherapistRoutes";

// Patient Pages
import {
  PatientOnboarding,
  SafetyPlanForPatients,
  SubmissionsForPatientsPage,
  TherapistProfileForPatientsPage,
} from "./Routes/PatientRoutes";
import { Helmet } from "react-helmet";
import AppRedirectButton from "../../components/utility/AppRedirectButton";
import AssignmentContextWrapper from "pages/AppPages/PatientPages/AssignmentContextWrapper";
import i18n from "i18next";
import { SupportPage, TemplatePreview } from "pages";
import AppLayoutNoContext from "../../layouts/AppLayout/AppLayoutNoContext";
import SingleGroupPage from "pages/AppPages/TherapistPages/SingleGroup/SingleGroup";

// Shared Authentication Page
const SignUp = lazy(() => import("../../pages/AuthenticationPages/SignUp"));
const LogIn = lazy(() => import("../../pages/AuthenticationPages/LogIn"));
const TherapistOnboarding = lazy(
  () => import("../../pages/AuthenticationPages/Onboarding/TherapistOnboarding")
);
const ConfirmEmail = lazy(
  () => import("../../pages/AuthenticationPages/ConfirmEmail")
);
const ForgotPassword = lazy(
  () => import("../../pages/AuthenticationPages/ForgotPassword")
);
const ResetPassword = lazy(
  () => import("../../pages/AuthenticationPages/ResetPassword")
);
const SetPassword = lazy(
  () => import("../../pages/AuthenticationPages/SetPassword")
);
const SetPasswordWithAPI = lazy(
  () => import("../../pages/AuthenticationPages/SetPasswordWithAPI")
);
const TwoFactorAuthPage = lazy(
  () => import("pages/AuthenticationPages/TwoFactorAuth")
);

// Shared Misc Pages
const SettingsPage = lazy(() => import("../../pages/AppPages/Settings"));
const LibraryPage = lazy(() => import("../../pages/AppPages/Library"));
const NotFoundPage = lazy(() => import("../../pages/NotFoundPage"));
const PaymentRedirect = lazy(() => import("../../pages/PaymentRedirectPage"));

// Invite Code Pages
const InviteCodePage = lazy(
  () => import("pages/InviteCodePages/InviteCodePage")
);
const TherapistNotAcceptingPage = lazy(
  () => import("pages/InviteCodePages/TherapistNotAcceptingPage")
);

// CommandBar
init(`${process.env.REACT_APP_COMMANDBAR_ID}`);

function App() {
  ReactGA.initialize(`${GA4}`);
  ReactGA.send("pageview");
  const { user, isTherapist, isPatient } = useCurrentUser();
  // universal toast handler
  const { hasUrlParams } = useQueryParams();
  const hasToastInParam = hasUrlParams("toast");
  useEffect(() => {
    if (hasToastInParam) {
      toast(hasToastInParam);
    }
  }, [hasToastInParam]);

  useEffect(() => {
    i18n.changeLanguage(user?.defaultLanguage);
    document.body.dir = i18n.dir();
  }, [user]);

  useEffect(() => {
    if (isTherapist) {
      const loggedInUserId = user?.id;
      window.CommandBar.boot(loggedInUserId, {
        userType: user?.type,
      });

      return () => {
        window.CommandBar.shutdown();
      };
    }
  }, [user, isTherapist]);

  // Upgrade user attributes in SimpleBase Chatbox after user login
  useEffect(() => {
    if (isTherapist) {
      const userAttributes = gatherUserAttributes(user, isTherapist);
      if (window?.simplebase?.updateUserAttributes) {
        window.simplebase.updateUserAttributes(userAttributes);
      } else {
        console.error("simplebase.updateUserAttributes is not defined");
      }
    }
  }, [user, isTherapist]);

  const DefaultRedirect = () => {
    return <Navigate to="/login" />;
  };

  return (
    <div className="reflective-app" data-testid={BRAND_NAME} id="app">
      <Helmet>
        {/* SimpleBase Chatbox  */}
        {isTherapist && (
          <script src="https://chatbox.simplebase.co/projects/6677fe9396ef7e2711d8b64d/widget.js"></script>
        )}
      </Helmet>
      <Suspense fallback={<PageLoading />}>
        <Router>
          <Routes>
            <Route path="/" element={<Navigate to="/dashboard" />} />
            <Route
              path="/signup"
              element={
                <AuthenticationLayout fullHeight>
                  <SignUp />
                </AuthenticationLayout>
              }
            />
            <Route
              path="/onboarding/therapist-profile"
              element={
                isTherapist ? (
                  <GenericLayout>
                    <TherapistOnboarding />
                  </GenericLayout>
                ) : (
                  <DefaultRedirect />
                )
              }
            />
            <Route
              path="/onboarding/practice-info"
              element={
                isTherapist ? (
                  <GenericLayout>
                    <TherapistOnboarding />
                  </GenericLayout>
                ) : (
                  <DefaultRedirect />
                )
              }
            />
            <Route
              path="/confirm-signup"
              element={
                <AuthenticationLayout>
                  <ConfirmEmail />
                </AuthenticationLayout>
              }
            />
            <Route
              path="/invite-from-therapist"
              element={
                <AuthenticationLayout>
                  <SetPasswordWithAPI />
                </AuthenticationLayout>
              }
            />
            <Route
              path="/login"
              element={
                <AuthenticationLayout fullHeight>
                  <LogIn />
                </AuthenticationLayout>
              }
            />
            <Route
              path="/login/2fa"
              element={
                <AuthenticationLayout fullHeight>
                  <TwoFactorAuthPage />
                </AuthenticationLayout>
              }
            />
            <Route
              path="/forgot-password"
              element={
                <AuthenticationLayout>
                  <ForgotPassword />
                </AuthenticationLayout>
              }
            />
            <Route
              path="/new-password"
              element={
                <AuthenticationLayout>
                  <ResetPassword />
                </AuthenticationLayout>
              }
            />
            <Route
              path="/set-password"
              element={
                <AuthenticationLayout>
                  <SetPassword />
                </AuthenticationLayout>
              }
            />

            <Route
              path="/patient-welcome"
              element={
                isPatient ? (
                  <OnboardingLayout>
                    <PatientOnboarding />
                  </OnboardingLayout>
                ) : (
                  <DefaultRedirect />
                )
              }
            />

            <Route
              path="/settings"
              element={
                <AppLayout>
                  <SettingsPage />
                </AppLayout>
              }
            />

            <Route
              path="/settings/:slug"
              element={
                <AppLayout>
                  <SettingsPage />
                </AppLayout>
              }
            />

            <Route
              path="/builder"
              element={
                isTherapist ? (
                  <GenericLayout hideLogo="true">
                    <AssignmentsBuilderPage />
                  </GenericLayout>
                ) : (
                  <DefaultRedirect />
                )
              }
            />
            <Route
              path="/builder/:slug"
              element={
                isTherapist ? (
                  <GenericLayout hideLogo="true">
                    <AssignmentsBuilderPage />
                  </GenericLayout>
                ) : (
                  <DefaultRedirect />
                )
              }
            />
            <Route
              path="/builder/:slug/:section"
              element={
                isTherapist ? (
                  <GenericLayout hideLogo="true">
                    <AssignmentsBuilderPage />
                  </GenericLayout>
                ) : (
                  <DefaultRedirect />
                )
              }
            />
            <Route
              path="/patients"
              element={
                isTherapist ? (
                  <AppLayout>
                    <PatientsPage />
                  </AppLayout>
                ) : (
                  <DefaultRedirect />
                )
              }
            />
            <Route
              path="/patients/:uuid"
              element={
                isTherapist ? (
                  <AppLayout>
                    <SinglePatientPage />
                  </AppLayout>
                ) : (
                  <DefaultRedirect />
                )
              }
            />
            <Route
              path="/patients/:uuid/:slug"
              element={
                isTherapist ? (
                  <AppLayout>
                    <SinglePatientPage />
                  </AppLayout>
                ) : (
                  <DefaultRedirect />
                )
              }
            />
            <Route
              path="/patients/:uuid/:slug/:assignment"
              element={
                isTherapist ? (
                  <AppLayout>
                    <AssignmentSubmissionsPage />
                  </AppLayout>
                ) : (
                  <DefaultRedirect />
                )
              }
            />
            <Route
              path="/dashboard"
              element={
                isTherapist ? (
                  <AppLayout>
                    <SubmissionsPage />
                  </AppLayout>
                ) : (
                  <DefaultRedirect />
                )
              }
            />
            <Route
              path="/dashboard/:slug"
              element={
                isTherapist ? (
                  <AppLayout>
                    <SubmissionsPage />
                  </AppLayout>
                ) : (
                  <DefaultRedirect />
                )
              }
            />

            <Route
              path="/resources"
              element={
                isTherapist ? (
                  <AppLayout>
                    <ResourcesPage />
                  </AppLayout>
                ) : (
                  <DefaultRedirect />
                )
              }
            />

            <Route
              path="/resources/:slug"
              element={
                isTherapist ? (
                  <AppLayout>
                    <ResourcesPage />
                  </AppLayout>
                ) : (
                  <DefaultRedirect />
                )
              }
            />

            <Route
              path="/resources/:slug/:patientSlug"
              element={
                isTherapist ? (
                  <AppLayout>
                    <ResourcesPage />
                  </AppLayout>
                ) : (
                  <DefaultRedirect />
                )
              }
            />

            <Route
              path="/session"
              element={
                isTherapist ? (
                  <GenericLayout hideLogo="true">
                    <OnlineSession />
                  </GenericLayout>
                ) : (
                  <DefaultRedirect />
                )
              }
            />

            <Route
              path="/session/:uuid"
              element={
                isTherapist ? (
                  <GenericLayout hideLogo="true">
                    <OnlineSession />
                  </GenericLayout>
                ) : (
                  <DefaultRedirect />
                )
              }
            />

            <Route
              path="/video-session"
              element={
                isTherapist ? (
                  <GenericLayout hideLogo="true">
                    <VideoSession />
                  </GenericLayout>
                ) : (
                  <DefaultRedirect />
                )
              }
            />

            <Route
              path="/your-assignments"
              element={
                isPatient ? (
                  <AppLayoutNoContext>
                    <AssignmentContextWrapper />
                  </AppLayoutNoContext>
                ) : (
                  <DefaultRedirect />
                )
              }
            />
            <Route
              path="/your-assignments/:slug"
              element={
                isPatient ? (
                  <AppLayoutNoContext>
                    <AssignmentContextWrapper />
                  </AppLayoutNoContext>
                ) : (
                  <DefaultRedirect />
                )
              }
            />
            <Route
              path="/your-assignments/:slug/:section"
              element={
                isPatient ? (
                  <AppLayoutNoContext>
                    <AssignmentContextWrapper />
                  </AppLayoutNoContext>
                ) : (
                  <DefaultRedirect />
                )
              }
            />
            <Route
              path="/your-assignments/:slug/:section/edit"
              element={
                isPatient ? (
                  <AppLayoutNoContext>
                    <AssignmentContextWrapper />
                  </AppLayoutNoContext>
                ) : (
                  <DefaultRedirect />
                )
              }
            />
            <Route
              path="/your-submissions"
              element={
                isPatient ? (
                  <AppLayout>
                    <SubmissionsForPatientsPage />
                  </AppLayout>
                ) : (
                  <DefaultRedirect />
                )
              }
            />
            <Route
              path="/your-submissions/:slug"
              element={
                isPatient ? (
                  <AppLayout>
                    <SubmissionsForPatientsPage />
                  </AppLayout>
                ) : (
                  <DefaultRedirect />
                )
              }
            />
            <Route
              path="/safety-plan"
              element={
                isPatient ? (
                  <AppLayout>
                    <SafetyPlanForPatients />
                  </AppLayout>
                ) : (
                  <DefaultRedirect />
                )
              }
            />
            <Route
              path="/your-therapist"
              element={
                <AppLayout>
                  <TherapistProfileForPatientsPage />
                </AppLayout>
              }
            />

            <Route
              path="/library"
              element={
                isPatient ? (
                  <AppLayout>
                    <LibraryPage />
                  </AppLayout>
                ) : (
                  <DefaultRedirect />
                )
              }
            />

            <Route
              path="/template-preview/:templateId"
              element={<TemplatePreview />}
            />

            <Route
              path="/invite-code/:therapistSlug/:codeSlug"
              element={<InviteCodePage />}
            />

            <Route
              path="/therapist-not-accepting"
              element={<TherapistNotAcceptingPage />}
            />

            <Route
              path="/support"
              element={
                isTherapist ? (
                  <AppLayout>
                    <SupportPage />
                  </AppLayout>
                ) : (
                  <DefaultRedirect />
                )
              }
            />

            <Route
              path="/groups"
              element={
                isTherapist ? (
                  <AppLayout>
                    <GroupsPage />
                  </AppLayout>
                ) : (
                  <DefaultRedirect />
                )
              }
            />

            <Route
              path="/groups/:uuid"
              element={
                isTherapist ? (
                  <AppLayout>
                    <SingleGroupPage />
                  </AppLayout>
                ) : (
                  <DefaultRedirect />
                )
              }
            />

            <Route
              path="/groups/:uuid/:slug"
              element={
                isTherapist ? (
                  <AppLayout>
                    <SingleGroupPage />
                  </AppLayout>
                ) : (
                  <DefaultRedirect />
                )
              }
            />

            <Route
              path="/please-wait"
              element={isTherapist ? <PaymentRedirect /> : <DefaultRedirect />}
            />

            <Route path="/not-found" element={<NotFoundPage />} />
            <Route path="*" element={<NotFoundPage />} />
          </Routes>
        </Router>
      </Suspense>
      <ToastContainer progressClassName="custom-toast-progress" />
      <ConfettiCanvas />
      <AppRedirectButton />
    </div>
  );
}

export default App;
