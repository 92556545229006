import "./GroupInfoCard.scss";
import React from "react";
import { Icon } from "components";
import { useViewport } from "utils/hooks";
import InfoIcon from "assets/icons/circle-info.svg";
import { Group } from "types";

interface GroupInfoCardProps {
  group: Group;
  showName?: boolean;
}

const GroupInfoCard = ({ group, showName = true }: GroupInfoCardProps) => {
  const { isDesktop } = useViewport();
  return (
    <div className="group-info-card">
      <div className="group-info-card__group_info">
        <table>
          <tbody>
            {isDesktop && showName && (
              <tr className="group-info-table-row">
                <td colSpan={2} className="group-info-table-label">
                  <b>{group?.name}</b>
                </td>
              </tr>
            )}
            <tr className="group-info-table-row">
              <td className="group-info-table-label">
                <Icon src={InfoIcon} />
              </td>
              <td>{group?.description}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default GroupInfoCard;
