import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { useLogout, useCurrentUser } from "utils/hooks";
import { deleteFileAction } from "store/actions/files";
import ReactGA from "react-ga4";

const useDeleteFile = () => {
  const dispatch = useDispatch();
  const { logOut } = useLogout();
  const { user } = useCurrentUser();

  const deleteFile = async (filePath: any) => {
    if (!filePath) {
      return null;
    }

    try {
      const response = await fetch(
        `${process.env.REACT_APP_REST_API_URL}/uploader/upload/delete`,
        {
          method: "POST",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ filePath }),
        }
      );

      if (response.ok) {
        const resData = await response.json();
        dispatch(deleteFileAction(resData.fileId));
        toast(`Files file deleted :)`);

        ReactGA.event("files_file_deleted", {
          user_id: user?.id,
          file_path: filePath,
        });
      } else if (response.status === 401) {
        toast.warn(`For security purposes please log in again.`);
        logOut();
      } else {
        const errorData = await response.json();
        toast.error(`Hmm, something went wrong. ${errorData.message}`);
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error(`Hmm, something went wrong. ${error}`);
    }
  };

  return {
    deleteFile,
  };
};

export default useDeleteFile;
