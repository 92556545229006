import "./GroupSubmissions.styles.scss";
import React, { useEffect, useMemo, useState } from "react";
import { PageActionBar } from "components";
import { useGetSubmissions } from "utils/hooks";
import { useParams } from "react-router-dom";
import SubmissionsTable from "pages/AppPages/Shared/Submissions/components/SubmissionsTable";
import useSingleGroupSubmissionsDataColumns from "../../utils/useSingleGroupSubmissionsDataColumns";
import SubmissionsTableSearch from "pages/AppPages/Shared/Submissions/components/SubmissionsTableSearch";
import { AssignmentSubmission } from "types";

const GroupSubmissions = () => {
  const { uuid } = useParams();
  const submissionDataColumns = useSingleGroupSubmissionsDataColumns();
  const { groupSubmissions } = useGetSubmissions({ groupSlug: uuid });
  const [filteredSubmissions, setFilteredSubmissions] =
    useState(groupSubmissions);
  const handleSearch = (searchedSubmissions: AssignmentSubmission) => {
    setFilteredSubmissions(searchedSubmissions);
  };
  const memoizedSubmissions = useMemo(
    () => filteredSubmissions,
    [filteredSubmissions]
  );
  const sortedSubmissions = memoizedSubmissions
    ?.slice()
    .sort((a: { createdAt: number }, b: { createdAt: number }) =>
      b.createdAt > a.createdAt ? 1 : a.createdAt > b.createdAt ? -1 : 0
    );

  useEffect(() => {
    setFilteredSubmissions(groupSubmissions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uuid]);

  return (
    <>
      <PageActionBar>
        <div id="group-page-left" className="flex">
          {groupSubmissions?.length > 0 && (
            <div className="group-page-stats">
              <SubmissionsTableSearch
                data={groupSubmissions}
                onChange={handleSearch}
              />
              <span className="group-page-stats__wrapper">
                <h4 className="group-page-stats__count">
                  {filteredSubmissions?.length} /{" "}
                  <span className="group-page-stats__total">
                    {groupSubmissions?.length}
                  </span>
                </h4>
              </span>
            </div>
          )}
        </div>
      </PageActionBar>
      <SubmissionsTable
        data={sortedSubmissions}
        submissionDataColumns={submissionDataColumns}
        defaultBaseUrl="groups"
        sluggable={false}
      />
    </>
  );
};

export default GroupSubmissions;
