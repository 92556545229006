import { useState } from "react";
import ReactGA from "react-ga4";
import { updateFile } from "store/actions/files";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import {
  useFetchFiles,
  useLogout,
  useCurrentUser,
  useGetTherapistFiles,
} from "utils/hooks";
import { formatInput } from "utils/helpers";
import { formattedSharedWith } from "./fileUtils";

interface UseUpdateFileProps {
  file: any;
  selectedPatients?: any[] | null;
  selectedGroups?: any[] | null;
}

const useUpdateFile = ({
  file,
  selectedPatients,
  selectedGroups,
}: UseUpdateFileProps) => {
  console.log("selectedGroups", selectedGroups);
  const [isUpdateSuccessful, setIsUpdateSuccessful] = useState(false);
  const dispatch = useDispatch();
  const { logOut } = useLogout();
  const { fetchTherapistFiles } = useFetchFiles();
  const { user } = useCurrentUser();
  const { therapistFiles } = useGetTherapistFiles({});

  const submitUpdateFile = (args: {
    fileUrl: any;
    title: any;
    description: any;
  }) => {
    if (!args) {
      return null;
    }

    const filteredFiles = therapistFiles.filter(
      (therapistFile: { uuid: any }) => therapistFile.uuid !== file.uuid
    );

    const existingUrl = filteredFiles.find(
      (therapistFile: { fileUrl: any }) =>
        therapistFile.fileUrl === args.fileUrl
    );

    if (existingUrl) {
      toast.warn(`A file with this url already exists in your files :)`);
      return null;
    }

    return new Promise<void>((resolve) => {
      setTimeout(() => {
        fetch(`${process.env.REACT_APP_API_URL}`, {
          method: "POST",
          credentials: "include",
          body: JSON.stringify({
            query: `
              mutation {
                updateFile(file: {
                  uuid: "${file.uuid}",
                  title: "${formatInput(args.title)}",
                  description: "${formatInput(args.description)}",
                  sharedWith: [${formattedSharedWith(selectedPatients)}],
                }) {
                  fileUrl
                  title
                  description
                  uuid
                  addedBy
                  updatedAt
                  sharedWith {
                    label
                    value
                  }
                }
              }
            `,
          }),
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        })
          .then(async (res) => {
            if (res.status !== 200 && res.status !== 201) {
              const error = await res.json();
              if (error.errors[0].message.includes("BRKN_6001")) {
                toast.warn(`For security purposes please log in again.`);
                logOut();
              } else {
                toast.error(
                  `${error.errors[0].message || "Hmm, something went wrong."}`
                );
                throw new Error("Failed!");
              }
            }
            return res.json();
          })
          .then((resData) => {
            if (resData.errors) {
              toast.error(
                `Hmm, something went wrong. ${resData.errors[0].message}`
              );
              resolve();
              return true;
            } else {
              fetchTherapistFiles();
              toast("File Updated");
              setIsUpdateSuccessful(true);
              resolve();
              ReactGA.event("files_file_added_success", {
                user_id: user?.id,
              });
              return dispatch(updateFile(resData.data.updateFile));
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }, 100);
    });
  };

  return {
    submitUpdateFile,
    isUpdateSuccessful,
  };
};

export default useUpdateFile;
