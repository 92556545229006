import "./GroupDeleteConfirmationModal.styles.scss";
import React, { useState, useEffect } from "react";
import { Icon, Button, Checkbox } from "components";
import WarningIcon from "assets/icons/exclamation-triangle.svg";
import { useDeleteGroup } from "utils/hooks";
import { Group } from "types";

interface GroupDeleteProps {
  group: Group;
  onCancel: () => void;
}

const GroupDeleteConfirmationModal = ({
  group,
  onCancel,
}: GroupDeleteProps) => {
  const { submitDeleteGroup } = useDeleteGroup();
  const [isDisabled, setIsDisabled] = useState(true);
  const [isConfirmed, setIsConfirmed] = useState(false);
  const [isUnderstood, setIsUnderstood] = useState(false);

  useEffect(() => {
    if (isConfirmed && isUnderstood) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [isConfirmed, isUnderstood]);

  return (
    <div className="group-deletion-confirmation">
      <div className="group-deletion-confirmation__icon">
        <Icon src={WarningIcon} />
      </div>
      <div className="group-deletion-confirmation__title">
        Are you sure you want to remove <b>{group?.name}</b>
      </div>
      <div className="group-deletion-confirmation__body">
        <span>
          You will no longer be connected to {group?.name} and you will no
          longer see submissions from them. <br />
          They can still access their assignments.
        </span>
      </div>
      <div className="group-deletion-confirmation__checkboxes">
        <Checkbox
          name="understand"
          label="I understand that this action cannot be undone."
          ariaLabel="I understand that this action cannot be undone."
          onChange={(e) => setIsUnderstood(e.target.checked)}
        />
        <Checkbox
          name="confirm"
          label="I understand that I will lose access to this group's data."
          ariaLabel="I understand that I will lose access to this group's data."
          onChange={(e) => setIsConfirmed(e.target.checked)}
        />
      </div>
      <div className="group-deletion-confirmation__buttons">
        <Button
          onClick={onCancel}
          name={`cancel_delete_group_button`}
          value="Never mind"
          extraClass="secondary-bland"
          size="small"
        />
        <Button
          onClick={() => {
            submitDeleteGroup(group);
            onCancel();
          }}
          name={`confirm_delete_group_button`}
          value="Yes, remove them"
          extraClass="danger"
          size="small"
          disabled={isDisabled}
        />
      </div>
    </div>
  );
};

export default GroupDeleteConfirmationModal;
