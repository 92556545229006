// import { Button, Divider, Modal } from "components";
import { Modal } from "components";
import React, { useState } from "react";
import ReactDOM from "react-dom";
import DeleteAssignmentConfirmationModal from "./DeleteAssignmentConfirmationModal";
import ReminderSettings from "./ReminderSettings/ReminderSettings";

const PortaledModal = ({ component }) => {
  const modalRoot = document.getElementById("root");

  return ReactDOM.createPortal(component, modalRoot);
};

const AssignmentSettings = ({
  assignment,
  onClose,
  hideNotificationSettings = false,
}) => {
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const handleAssignmentDeleted = () => {
    setIsDeleteModalOpen(false);
    onClose();
  };

  return (
    <>
      <div>
        {assignment?.notification && !hideNotificationSettings && (
          <>
            <ReminderSettings
              notification={assignment?.notification}
              entityId={assignment?.uuid}
            />
          </>
        )}
      </div>
      <PortaledModal
        component={
          <Modal
            isOpen={isDeleteModalOpen}
            onClose={() => setIsDeleteModalOpen(false)}
          >
            <DeleteAssignmentConfirmationModal
              assignment={assignment}
              isForGroup={true}
              onCancel={() => setIsDeleteModalOpen(false)}
              onDelete={handleAssignmentDeleted}
            />
          </Modal>
        }
      />
    </>
  );
};

export default AssignmentSettings;
