/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import "react-sliding-pane/dist/react-sliding-pane.css";
import "./SidePanel.styles.scss";
import React, { useState, useEffect } from "react";
import { Portal } from "react-portal";
import SlidingPane from "react-sliding-pane";
import { Icon } from "components";
import PropTypes from "prop-types";

const SidePanelHeader = ({ title, OnIconClick }) => {
  return (
    <div className="side-panel__header flex">
      <div className="side-panel__header-icon" onClick={OnIconClick}>
        <Icon src={"ArrowBack"} />
      </div>
      {typeof title === "string" ? (
        <h4 className="side-panel__header-title">{title}</h4>
      ) : (
        title
      )}
    </div>
  );
};

const SidePanel = ({
  isVisible,
  onClose,
  placement,
  title,
  children,
  extraClass,
}) => {
  const [isPanelVisible, setIsPanelVisible] = useState(isVisible);

  useEffect(() => {
    setIsPanelVisible(isVisible);
  }, [isVisible]);

  const handleOnClose = () => {
    onClose();
  };

  return (
    <Portal>
      <SlidingPane
        from={placement}
        isOpen={isPanelVisible}
        onRequestClose={handleOnClose}
        className={`side-panel ${extraClass}`}
        overlayClassName={`side-panel-background ${extraClass}`}
        width="400px"
        shouldCloseOnEsc
        hideHeader
      >
        <SidePanelHeader title={title} OnIconClick={handleOnClose} />
        {children}
      </SlidingPane>
    </Portal>
  );
};

SidePanel.propTypes = {
  isVisible: PropTypes.bool,
  onClose: PropTypes.func,
  placement: PropTypes.string,
  extraClass: PropTypes.string,
};

SidePanel.defaultProps = {
  isVisible: false,
  placement: "right",
};

export default SidePanel;
