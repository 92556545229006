import React from "react";
import InviteCodeFooter from "pages/InviteCodePages/components/InviteCodeFooter";
import { inviteCodeInfo } from "types";
import "./InviteCodeContent.styles.scss";

interface InviteCodeContentProps {
  inviteCodeInfo: inviteCodeInfo;
}

const InviteCodeContent = React.forwardRef<
  HTMLDivElement,
  InviteCodeContentProps
>(({ inviteCodeInfo }, ref) => {
  const {
    codeUrl,
    inviteUrl,
    therapistEmail,
    therapistFullName,
    therapistCredentials,
  } = inviteCodeInfo ?? {};

  return (
    <div ref={ref} className="invite-code-content">
      <h1 className="invite-code-content__title">
        Add me as your therapist on Reflective
      </h1>
      <p>
        By scanning this QR code, you&apos;ll be able to add me,{" "}
        {therapistFullName},{" "}
        {therapistCredentials &&
          `${therapistCredentials[0].split(",").join(", ")}, `}
        as your therapist on Reflective. You&apos;ll be able to access links and
        exercises I share that will aid you in your mental health journey. My
        email is <a href={`mailto:${therapistEmail}`}>{therapistEmail}</a>.
      </p>
      <img src={codeUrl} alt="QR code" className="invite-code-content__code" />
      {!ref && (
        <p>
          Can&apos;t scan the QR code? <a href={inviteUrl}>Click here</a> to
          proceed.
        </p>
      )}
      <InviteCodeFooter />
    </div>
  );
});

InviteCodeContent.displayName = "InviteCodeContent";

export default InviteCodeContent;
