import "./LibraryEmptyState.styles.scss";
import React from "react";
import BirdTwo from "assets/images/bird-2.png";
import { useCurrentUser } from "utils/hooks";
import { useTranslation } from "react-i18next";

const LibraryEmptyState = () => {
  const { t } = useTranslation(["common"]);
  const { isPatient } = useCurrentUser();
  return (
    <div className="library-empty-state">
      <h3>{t("patient_library_page.library_empty_header")}</h3>
      <p>
        {isPatient
          ? t("patient_library_page.library_empty_patient_paragraph")
          : t("patient_library_page.library_empty_therapist_paragraph")}
      </p>
      <div className="library-empty-state__image-holder">
        <img
          className="library-empty-state__image"
          src={BirdTwo}
          alt="small bird"
        />
      </div>
    </div>
  );
};

export default LibraryEmptyState;
