import { PURGE } from "redux-persist";

const initialState = {
  files: [],
  therapistFiles: [],
  patientFiles: [],
  attachedFiles: [],
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case "FETCH_PUBLIC_FILES":
      return {
        ...state,
        files: [...payload],
      };
    case "FETCH_PATIENT_FILES":
      return {
        ...state,
        patientFiles: [...payload],
      };
    case "FETCH_THERAPIST_FILES":
      return {
        ...state,
        therapistFiles: [...payload],
      };
    case "FETCH_FILE_ATTACHMENT":
      return {
        ...state,
        attachedFiles: [...state.attachedFiles, payload],
      };
    case "ADD_THERAPIST_FILE":
      return {
        ...state,
        therapistFiles: [payload, ...state.therapistFiles],
      };
    case "UPDATE_THERAPIST_FILE":
      return {
        ...state,
        therapistFiles: state.therapistFiles.map((therapistfile) =>
          therapistfile.uuid === payload.uuid
            ? {
                ...therapistfile,
                ...payload,
              }
            : therapistfile
        ),
      };
    case "DELETE_THERAPIST_FILE":
      return {
        ...state,
        therapistFiles: state.therapistFiles.filter(
          (file) => file.uuid !== payload
        ),
      };
    case PURGE:
      return initialState;
    default:
      return state;
  }
};
