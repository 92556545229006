import "./IconButton.styles.scss";
import { Icon } from "components";
import React from "react";

interface IconButtonProps {
  icon: string;
  onClick: () => void;
}

const IconButton = ({ icon, onClick }: IconButtonProps) => {
  return (
    <button className="icon-button" onClick={onClick}>
      <Icon src={icon} />
    </button>
  );
};

export default IconButton;
