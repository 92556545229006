import ReactDOM from "react-dom";
import "./GroupWorkbook.styles.scss";
import React, { useEffect, useState } from "react";
import {
  useGetAssignments,
  useGetGroups,
  useQueryParams,
  useGetSubmissions,
} from "utils/hooks";
import {
  PageActionBar,
  AssignmentCard,
  AssignmentIcon,
  SidePanel,
  Modal,
} from "components";
import { useNavigate, useParams } from "react-router-dom";
import AddAssignmentsButton from "pages/AppPages/TherapistPages/SingleGroup/components/GroupWorkbook/components/AddAssignmentsButton";
import { ASSIGNMENT_STATUSES } from "utils/constants";
import useAssignmentDetailsTabs from "./components/AssignmentDetails/useAssignmentDetailsTabs";
import AssignmentSettings from "./components/AssignmentDetails/AssignmentSettings/AssignmentSettings";
import AssignmentFields from "./components/AssignmentDetails/AssignmentFields";
import WorkbookEmptyState from "./components/WorkbookEmptyState";
import ReminderDetails from "./components/ReminderDetails";
import DeleteAssignmentConfirmationModal from "./components/AssignmentDetails/AssignmentSettings/DeleteAssignmentConfirmationModal";
import { toAmericanDate, toUnixTimestamp } from "utils/helpers";
import { Assignment } from "types";

interface PortaledModalProps {
  component: React.ReactNode;
}

const PortaledModal: React.FC<PortaledModalProps> = ({ component }) => {
  const modalRoot = document.getElementById("root");

  return modalRoot ? ReactDOM.createPortal(component, modalRoot) : null;
};

const GroupWorkbook: React.FC = () => {
  const { uuid } = useParams<{ uuid: string }>();
  const { specificGroup: group } = useGetGroups({ slug: uuid });
  const history = useNavigate();

  const { hasUrlParams } = useQueryParams();
  const isFromNewAssignment = hasUrlParams("assignment-added");
  const assignmentSlug = hasUrlParams("assignment");

  const { assignments } = useGetAssignments({
    groupId: group?.uuid,
  });
  const { groupSubmissions } = useGetSubmissions({ groupSlug: uuid });

  const [currentAssignment, setCurrentAssignment] = useState<any>(null);
  const [showAssignmentPanel, setShowAssignmentPanel] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [assignmentToDelete, setAssignmentToDelete] = useState<any>(null);
  const [sidePanelTitle, setSidePanelTitle] = useState<string | undefined>();

  const { assignmentDetailTabs, activeTab, handleTabChange } =
    useAssignmentDetailsTabs();

  const handleAssignmentPanelClose = () => {
    setCurrentAssignment(null);
    setShowAssignmentPanel(false);
    history(`/groups/${group?.slug}/workbook`);
  };

  const visibleAssignments = (assignments || [])
    .filter(
      ({ status, type }: any) =>
        status === ASSIGNMENT_STATUSES.VISIBLE && type !== "SAFETY_PLAN"
    )
    .slice()
    .sort(
      (a: { createdAt: number }, b: { createdAt: number }) =>
        b.createdAt - a.createdAt
    );

  useEffect(() => {
    if (isFromNewAssignment && assignmentSlug) {
      const assignmentFromSlug = assignments?.find(
        (a: { slug: string }) => a.slug === assignmentSlug
      );
      setCurrentAssignment(assignmentFromSlug || null);
      setShowAssignmentPanel(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFromNewAssignment, assignmentSlug]);

  return (
    <>
      <div className="workbook-page">
        <PageActionBar>
          <div className="workbook-page-header">
            <AddAssignmentsButton groupSlug={group?.slug} />
          </div>
        </PageActionBar>

        <div className="workbook-page-body">
          <div className="workbook-page-section">
            {visibleAssignments?.length === 0 ? (
              <WorkbookEmptyState group={group} />
            ) : (
              <>
                {visibleAssignments?.map((assignment: Assignment) => {
                  const { notification } = assignment;

                  const notificationsLive = notification?.status === "LIVE";
                  const notificationEndTimestamp =
                    notification?.endDate && notification.endDate !== "null"
                      ? toUnixTimestamp(notification.endDate)
                      : null;

                  const notificationsExpired =
                    notificationEndTimestamp !== null
                      ? // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                        notificationEndTimestamp < toUnixTimestamp(new Date())!
                      : false;

                  return (
                    <AssignmentCard
                      isExpired={notificationsExpired}
                      isActive={false}
                      showSubtitle={notificationsLive}
                      subtitle={
                        notificationsLive && !notificationsExpired ? (
                          <ReminderDetails notification={notification} />
                        ) : notificationsExpired ? (
                          <span>
                            Completed: {toAmericanDate(notification?.endDate)}
                          </span>
                        ) : null
                      }
                      key={assignment.slug}
                      assignment={assignment}
                      submissions={groupSubmissions}
                      onRemoveClick={() => {
                        setAssignmentToDelete(assignment);
                        setIsDeleteModalOpen(true);
                      }}
                      onScheduleClick={() => {
                        handleTabChange(assignmentDetailTabs[1]);
                        setCurrentAssignment(assignment);
                        setShowAssignmentPanel(true);
                        setSidePanelTitle("");
                      }}
                      onPreviewClick={() => {
                        handleTabChange(assignmentDetailTabs[0]);
                        setCurrentAssignment(assignment);
                        setShowAssignmentPanel(true);
                        setSidePanelTitle("Preview");
                      }}
                    />
                  );
                })}
              </>
            )}
          </div>
        </div>
      </div>

      <SidePanel
        title={
          <div className="group-workbook-panel-tabs">
            <AssignmentIcon
              icon={currentAssignment?.icon?.src}
              style={currentAssignment?.type}
              color={currentAssignment?.color}
            />
            <h4 className="side-panel__header-title">
              {currentAssignment?.label}
            </h4>
          </div>
        }
        isVisible={showAssignmentPanel}
        onClose={handleAssignmentPanelClose}
      >
        {sidePanelTitle && (
          <h1 className="side-panel_title">{sidePanelTitle}</h1>
        )}
        <>
          {activeTab.name === "Settings" ? (
            <AssignmentSettings
              assignment={currentAssignment}
              onClose={handleAssignmentPanelClose}
            />
          ) : (
            <AssignmentFields assignment={currentAssignment} />
          )}
        </>
      </SidePanel>

      <PortaledModal
        component={
          <Modal
            isOpen={isDeleteModalOpen}
            onClose={() => setIsDeleteModalOpen(false)}
          >
            <DeleteAssignmentConfirmationModal
              assignment={assignmentToDelete}
              isForGroup={true}
              onCancel={() => setIsDeleteModalOpen(false)}
            />
          </Modal>
        }
      />
    </>
  );
};

export default GroupWorkbook;
