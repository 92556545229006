import { useState } from "react";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { addFileAction } from "store/actions/files";

interface onFileUploadProps {
  file?: File | null;
  args: any;
  onSuccess: (f: FileResponse["fileData"] | string) => void;
  allData?: boolean;
}
export interface FileResponse {
  fileData: {
    uuid: string;
    fileUrl: string;
    added_by: string;
    title: string;
    description: string;
    updatedAt: string;
    createdAt: string;
  };
}
const useFileUploader = () => {
  const dispatch = useDispatch();
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [message, setMessage] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const onFileChange = (files: null | File[]) => {
    const file = files && files[0];
    setSelectedFile(file);
    setMessage("");
  };

  const onFileUpload = async ({
    file,
    args = {
      isImage: false,
    },
    onSuccess,
    allData,
  }: onFileUploadProps) => {
    setIsLoading(true);
    const uploadFile = file || selectedFile;

    if (uploadFile) {
      if (uploadFile.size > 2 * 1024 * 1024 && !args.isImage) {
        setMessage("File size exceeds 2MB limit");
        setIsLoading(false);
        return;
      }

      const formData = new FormData();
      formData.append("file", uploadFile);
      formData.append("args", JSON.stringify(args));

      try {
        const response = await fetch(
          `${process.env.REACT_APP_REST_API_URL}/uploader/upload`,
          {
            method: "POST",
            body: formData,
            credentials: "include",
          }
        );

        if (response.ok) {
          setMessage("File uploaded successfully");
          toast("File Added");
          const resData: FileResponse = await response.json();
          dispatch(addFileAction(resData.fileData));

          if (allData) {
            onSuccess && onSuccess(resData.fileData);
          } else {
            onSuccess && onSuccess(resData.fileData.uuid);
          }
        } else {
          const errorData = await response.json();
          if (errorData.error.includes("FILE_LIMIT_REACHED")) {
            toast.warn(`File limit reached, please upgrade`);
            setIsLoading(false);
          } else {
            setMessage(`Upload failed: ${errorData.message}`);
          }
        }
      } catch (error) {
        console.error("Error:", error);
        setMessage(`Error: ${error}`);
      }

      setIsLoading(false);
    } else {
      setMessage("No file selected");
      setIsLoading(false);
    }
  };

  return {
    onFileChange,
    onFileUpload,
    selectedFile,
    message,
    isLoading,
  };
};

export default useFileUploader;
