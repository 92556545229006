import { useState } from "react";
import ReactGA from "react-ga4";
import { updateNoteAction } from "store/actions/notes";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { useLogout, useCurrentUser } from "utils/hooks";
import { formatInput } from "utils/helpers";
import useFetchNotes from "./useFetchNotes";

const useUpdateNote = ({ noteId }) => {
  const [isUpdateSuccessful, setIsUpdateSuccessful] = useState(false);
  const dispatch = useDispatch();
  const { logOut } = useLogout();
  const { fetchNotes } = useFetchNotes();
  const { user } = useCurrentUser();

  const submitUpdateNote = (args) => {
    if (!args) {
      return null;
    }

    return new Promise((resolve) => {
      setTimeout(() => {
        fetch(`${process.env.REACT_APP_API_URL}`, {
          method: "POST",
          credentials: "include",
          body: JSON.stringify({
            query: `
              mutation {
                updateNote(note: {
                  date: "${args.date}",
                  body: "${formatInput(args.body)}",
                  referenceUrl: "${formatInput(args.referenceUrl)}",
                  uuid: "${noteId}",
                }) {
                  uuid
                  date
                  body
                  referenceUrl
                  patientId
                  createdAt
                  updatedAt
                }
              }
            `,
          }),
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        })
          .then(async (res) => {
            if (res.status !== 200 && res.status !== 201) {
              const error = await res.json();
              if (error.errors[0].message.includes("BRKN_6001")) {
                toast.warn(`For security purposes please log in again.`);
                logOut();
              } else {
                toast.error(
                  `${error.errors[0].message || "Hmm, something went wrong."}`
                );
                throw new Error("Failed!");
              }
            }
            return res.json();
          })
          .then((resData) => {
            if (resData.errors) {
              toast.error(
                `Hmm, something went wrong. ${resData.errors[0].message}`
              );
              resolve();
              return true;
            } else {
              fetchNotes();
              toast("Note Updated");
              setIsUpdateSuccessful(true);
              resolve();
              ReactGA.event("note_updated_success", {
                user_id: user?.id,
                note_id: args.noteId,
              });
              return dispatch(updateNoteAction(resData.data.updateNote));
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }, 100);
    });
  };

  return {
    submitUpdateNote,
    isUpdateSuccessful,
  };
};

export default useUpdateNote;
