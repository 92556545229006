import { useEffect } from "react";
import { useSelector } from "react-redux";
import useFetchFiles from "./useFetchFiles";

const useGetPatientFiles = () => {
  const { fetchPatientFiles } = useFetchFiles();

  const files = useSelector((state) => state.files && state.files);

  useEffect(() => {
    if (files?.patientFiles?.length === 0) {
      fetchPatientFiles();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    patientFiles: files?.patientFiles || [],
  };
};

export default useGetPatientFiles;
