import { useState } from "react";
import ReactGA from "react-ga4";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { useLogout, useCurrentUser } from "utils/hooks";
import useFetchGroups from "./useFetchGroups";
import { deleteGroupAction } from "store/actions/groups";
import { Group } from "types";

const useDeleteGroup = () => {
  const [isSubmitSuccessful, setIsSubmitSuccessful] = useState(false);
  const dispatch = useDispatch();
  const { logOut } = useLogout();
  const { fetchGroups } = useFetchGroups();
  const { user } = useCurrentUser();

  const submitDeleteGroup = (group: Group) => {
    if (!group) {
      return null;
    }

    return new Promise<void>((resolve) => {
      setTimeout(() => {
        fetch(`${process.env.REACT_APP_API_URL}`, {
          method: "POST",
          credentials: "include",
          body: JSON.stringify({
            query: `
              mutation {
                deleteGroup(uuid: "${group.uuid}")
              }
            `,
          }),
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        })
          .then(async (res) => {
            if (res.status !== 200 && res.status !== 201) {
              const error = await res.json();
              if (error.errors[0].message.includes("BRKN_6001")) {
                setIsSubmitSuccessful(true);
                toast.warn(`For security purposes please log in again.`);
                logOut();
              } else {
                setIsSubmitSuccessful(true);
                toast.error(
                  `${error.errors[0].message || "Hmm, something went wrong."}`
                );
                throw new Error("Failed!");
              }
            }
            return res.json();
          })
          .then((resData) => {
            if (resData.errors) {
              toast.error(
                `Hmm, something went wrong. ${resData.errors[0].message}`
              );
              setIsSubmitSuccessful(true);
              resolve();
              return true;
            } else {
              fetchGroups();
              toast("Your assignment group has been deleted");
              setIsSubmitSuccessful(true);
              resolve();
              ReactGA.event("custom_assignment_deleted_success", {
                user_id: user?.id,
                slug: group.slug,
              });
              return dispatch(deleteGroupAction(resData.data.deleteGroup));
            }
          })
          .catch((err) => {
            setIsSubmitSuccessful(true);
            console.log(err);
          });
      }, 100);
    });
  };

  return {
    submitDeleteGroup,
    isSubmitSuccessful,
  };
};

export default useDeleteGroup;
