import { RootStateOrAny, useSelector } from "react-redux";
import { Assignment } from "types";
import { ASSIGNMENT_STATUSES } from "utils/constants";

interface UseGetAssignmentsProps {
  assignmentId?: string;
  patientId?: string;
  groupId?: string;
}

const useGetAssignments = ({
  assignmentId,
  patientId,
  groupId,
}: UseGetAssignmentsProps) => {
  const allAssignments = useSelector(
    (state: RootStateOrAny) => state.assignments && state.assignments
  );

  const patientAssignments = allAssignments.assignments.filter(
    (assignment: Assignment) => assignment.patientId === patientId
  );

  const patientAssignment = allAssignments?.assignments?.find(
    (a: Assignment) => a.uuid === assignmentId
  );

  const groupAssignments = allAssignments.assignments.filter(
    (assignment: Assignment) => assignment.groupId === groupId
  );

  const allVisibleAssignments = allAssignments.assignments.filter(
    (assignment: Assignment) =>
      assignment.status === ASSIGNMENT_STATUSES.VISIBLE &&
      assignment.type !== "SAFETY_PLAN"
  );

  return {
    assignment: patientAssignment,
    assignments: patientAssignments,
    allVisibleAssignments,
    hasAssignments: allAssignments.assignments.length > 0,
    patientAssignment: patientAssignment,
    groupAssignments,
  };
};

export default useGetAssignments;
