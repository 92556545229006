/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import "./GroupActionsMenu.styles.scss";
import React, { useState } from "react";
import { Icon, Modal } from "components";
import MoreIcon from "assets/icons/ellipsis-vertical.svg";
import TrashIcon from "assets/icons/trash.svg";
import { useClickOutside } from "utils/hooks";
import GroupDeleteConfirmationModal from "./GroupDeleteConfirmationModal";
import { Group } from "types";

interface GroupActionsMenuProps {
  group: Group;
}

const GroupActionsMenu = ({ group }: GroupActionsMenuProps) => {
  const { node, isNodeVisible, setIsNodeVisible } = useClickOutside();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  return (
    <div className="group_action-menu-wrapper">
      <div className="group_action-menu">
        <span
          onClick={(e) => {
            e.stopPropagation();
            setIsNodeVisible(!isNodeVisible);
          }}
        >
          <Icon src={MoreIcon} />
        </span>
      </div>
      <div
        ref={node}
        className={`group_action-menu-dropdown ${
          isNodeVisible ? "group_action-menu-dropdown-visible" : ""
        }`}
      >
        <ul className="group_action-menu-dropdown-menu">
          <li
            className="group_action-menu-dropdown-menu-item group_action-menu-dropdown-menu-item-delete-item"
            onClick={(e) => {
              e.stopPropagation();
              setIsDeleteModalOpen(true);
            }}
          >
            <Icon src={TrashIcon} /> Remove
          </li>
        </ul>
      </div>
      <Modal
        isOpen={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
      >
        <GroupDeleteConfirmationModal
          group={group}
          onCancel={() => setIsDeleteModalOpen(false)}
        />
      </Modal>
    </div>
  );
};

export default GroupActionsMenu;
