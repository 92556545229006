import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Animate, Button, Input, PatientsDropdown, Textarea } from "components";

import { useAddLibraryResource, useUpdateLibraryResource } from "utils/hooks";
import { Patient } from "types";

interface AddLibraryResourceFormProps {
  onSuccess: () => void;
  resource: any;
  showPatients?: boolean;
  showGroups?: boolean;
  defaultSharedWith?: any;
}

const AddLibraryResourceForm = ({
  onSuccess,
  resource,
  showPatients = true,
  defaultSharedWith = null,
}: AddLibraryResourceFormProps) => {
  const [selectedPatients, setSelectedPatients] = useState(
    resource?.sharedWith || null
  );
  const onPatientSelect = (patients: Patient[]) => {
    setSelectedPatients(patients);
  };

  const isFullNameNullable =
    defaultSharedWith?.firstName === null &&
    defaultSharedWith?.lastName === null;

  const formattedDefaultSharedWith = defaultSharedWith
    ? {
        value: defaultSharedWith.uuid,
        label: isFullNameNullable
          ? defaultSharedWith.email
          : `${defaultSharedWith.firstName} ${defaultSharedWith.lastName}`,
      }
    : null;

  const { register, handleSubmit, errors, formState } = useForm();
  const { submitAddLibraryResource, isSubmitSuccessful } =
    useAddLibraryResource({
      selectedPatients: [formattedDefaultSharedWith] || selectedPatients,
    });
  const { submitUpdateLibraryResource, isUpdateSuccessful } =
    useUpdateLibraryResource({ resource, selectedPatients });
  const { isSubmitting } = formState;

  useEffect(() => {
    if (isSubmitSuccessful || isUpdateSuccessful) {
      onSuccess();
    }
  }, [isSubmitSuccessful, onSuccess, isUpdateSuccessful]);

  return (
    <div id="add_link_form">
      <form
        onSubmit={handleSubmit(
          resource ? submitUpdateLibraryResource : submitAddLibraryResource
        )}
        autoComplete="off"
      >
        <Animate animation="fadeInLeft" delay=".15">
          <Input
            name="resourceUrl"
            type="url"
            label="URL"
            placeholder="e.g. https://www.youtube.com/watch?v=q6aAQgXauQw"
            forwardRef={register({ required: true })}
            error={errors.resourceUrl && "URL is required"}
            defaultValue={resource && resource.resourceUrl}
          />
        </Animate>
        <Animate animation="fadeInLeft" delay=".25">
          <Input
            name="imageUrl"
            type="url"
            label="Image URL"
            placeholder="e.g. https://img.youtube.com/vi/q6aAQgXauQw/maxresdefault.jpg"
            forwardRef={register()}
            optionalField
            defaultValue={resource && resource.imageUrl}
          />
        </Animate>
        <Animate animation="fadeInLeft" delay=".35">
          <Input
            name="title"
            type="text"
            label="Title"
            placeholder="e.g. What is Cognitive Behavioral Therapy?"
            forwardRef={register({ required: true })}
            error={errors.title && "Title field is required"}
            defaultValue={resource && resource.title}
          />
        </Animate>
        <Animate animation="fadeInLeft" delay=".45">
          <Textarea
            name="description"
            label="Description"
            placeholder={
              "e.g CBT is an evidence-based treatment that can help people with depression, anxiety, panic attacks, hard relationships, and many other problems."
            }
            forwardRef={register({ required: true })}
            error={errors.description && "Description field is required"}
            rows="5"
            maxLength={240}
            defaultValue={resource && resource.description}
          />
        </Animate>
        {showPatients && (
          <PatientsDropdown
            name="selectedPatients"
            isMulti
            label="Share with"
            includeEmail={false}
            placeholder="Choose patients"
            defaultPatient={null}
            onChange={onPatientSelect}
            allowSelectAll
            closeMenuOnSelect={false}
            hideSelectedOptions={false}
            value={formattedDefaultSharedWith || selectedPatients}
          />
        )}
        <Button
          type="submit"
          name="add_or_edit_resource_button"
          value={resource ? "Save Resource" : "Add Resource"}
          fullWidth
          isLoading={isSubmitting}
        />
      </form>
    </div>
  );
};

export default AddLibraryResourceForm;
