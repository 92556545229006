import React, { useEffect } from "react";
import { Button } from "components";
import {
  useFetchInviteCodes,
  useGetInviteCodes,
  useCreateInviteCode,
  useCurrentUser,
  useGetPatients,
} from "utils/hooks";
import QRCode from "./components/QRCode";
import UpgradeMessage from "./components/UpgradeMessage";
import { InviteQRCode } from "types";
import { useTranslation } from "react-i18next";
import "./InviteCodes.styles.scss";

const InviteCode = () => {
  const { fetchInviteCodes } = useFetchInviteCodes();
  const { createInviteCode, isLoading } = useCreateInviteCode();
  const { inviteCodes } = useGetInviteCodes();
  const { user } = useCurrentUser();
  const { patients } = useGetPatients({});
  const { t } = useTranslation(["common"]);

  const patientLimit = user?.plan?.patientLimit || 0;
  // if the user doesn't have a plan they are likley a patient who cannot currently add invite codes anyway
  const limitReached = patients.length >= patientLimit;

  useEffect(() => {
    fetchInviteCodes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <section className="settings-section" id="invite-code">
      <h2 className="section-title">{t("invite_code.invite_code_label")}</h2>
      <hr className="divider" />
      <div className="settings-section__content">
        <p>{t("invite_code.invite_code_explainer")}</p>
        {inviteCodes.length > 0 && (
          <>
            {inviteCodes.map((code: InviteQRCode) => (
              <QRCode code={code} key={code.uuid} />
            ))}
            {limitReached && (
              <p className="settings-section__qr-code-note">
                <small>{t("invite_code.invite_code_not_active_note")}</small>
              </p>
            )}
          </>
        )}
        <Button
          name="generate_code"
          value={t("invite_code.generate_code")}
          onClick={() => createInviteCode()}
          isLoading={isLoading}
          disabled={inviteCodes.length > 0 || limitReached}
          extraClass="settings-section__qr-code-btn"
        />
        {limitReached && (
          <UpgradeMessage
            limitedEntity=""
            warningLine={t("invite_code.upgrade_tooltip_explainer")}
            valueLine={t("invite_code.upgrade_tooltip_cta")}
          />
        )}
      </div>
    </section>
  );
};

export default InviteCode;
