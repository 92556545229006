import React from "react";
import { Animate, FileUploader } from "components";

interface AddFileFormProps {
  onSuccess: () => void;
  currentFile: any;
  defaultSharedWith?: any;
}

const AddFileForm = ({
  onSuccess,
  currentFile,
  defaultSharedWith,
}: AddFileFormProps) => {
  return (
    <div id="add_file_form">
      <Animate animation="fadeInLeft" delay=".25">
        <label className="input__label mb-2" htmlFor="file-uploader">
          File
        </label>
        <FileUploader
          onSuccess={() => onSuccess()}
          showPatients={true}
          currentFile={currentFile}
          defaultSharedWith={defaultSharedWith}
        />
      </Animate>
    </div>
  );
};

export default AddFileForm;
