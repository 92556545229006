import { toast } from "react-toastify";
import { useDispatch, useSelector, RootStateOrAny } from "react-redux";
import { useLogout } from "utils/hooks";
import {
  updatePatientAssignment,
  updateUnsavedConfigureSettings,
} from "store/actions/assignments";

type UpdateItem = {
  type: string;
  sectionId: string;
  fieldOrder: number;
  value: string;
};

const useUpdateAssignment = () => {
  const { logOut } = useLogout();
  const dispatch = useDispatch();

  const unsavedConfigureSettings = useSelector(
    (state: RootStateOrAny) =>
      state.assignments.unsavedConfigureSettings &&
      state.assignments.unsavedConfigureSettings
  );

  const updateUnsavedConfigSettings = (settings: UpdateItem[] | []) => {
    dispatch(updateUnsavedConfigureSettings(settings));
  };

  const updateAssignmentForPatientOrGroup: any = (assignmentId: string) => {
    const convertedData = unsavedConfigureSettings?.map((item: any) => {
      return `{
        type: "${item.type}",
        sectionId: "${item.sectionId}",
        fieldOrder: "${item.fieldOrder.toString()}",
        value: "${item.value}"   
      }`;
    });

    return new Promise(() => {
      setTimeout(() => {
        fetch(`${process.env.REACT_APP_API_URL}`, {
          method: "POST",
          credentials: "include",
          body: JSON.stringify({
            query: `
            mutation {
              updateAssignmentForPatientOrGroup(
                assignmentId: "${assignmentId}"
                updates: [${convertedData}]
              ) {
                uuid
                type
                label
                status
                actionPlanId
                patientId
                slug
                instructions
                createdAt
                order
                icon {
                  name
                  src
                }
                color
                notification {
                  startDate
                  endDate
                  notificationTime
                  notificationMessage
                  daysOfWeek
                  status
                }
                sections {
                  uuid
                  name
                  assignmentId
                  createdAt
                  type
                  label
                  status
                  order
                  theme
                  slug
                  description
                  fields {
                    uuid
                    name
                    sectionId
                    type
                    label
                    status
                    placeholder
                    hint
                    defaultValue
                    min
                    max
                    choicesKey
                    required
                    order
                    config
                    inlineValue
                    videoURL
                    fileId
                    fileUrl
                    richText
                    fromPrevious
                    pinTitle
                    pinToSection
                    fromPreviousField
                    usePrevious
                    moodScale {
                      uuid
                      label
                      description 
                      emojis {
                          emoji
                          label
                      }
                    }
                    multiResponseOptions{
                      placeholder
                      }
                    options
                    moods
                  }    
                }
              }
            }
          `,
          }),
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        })
          .then(async (res) => {
            if (res.status !== 200 && res.status !== 201) {
              const error = await res.json();
              if (error.errors[0].message.includes("BRKN_6001")) {
                toast.warn(`For security purposes please log in again.`);
                logOut();
              } else {
                toast.error(`Hmm, something went wrong.`);
                throw new Error("Failed!");
              }
            }
            return res.json();
          })
          .then((resData) => {
            if (resData.errors) {
              toast.error(
                `Hmm, something went wrong. ${resData.errors[0].message}`
              );
            } else {
              dispatch(
                updatePatientAssignment(
                  resData.data.updateAssignmentForPatientOrGroup
                )
              );
              return resData.data.updateAssignmentForPatientOrGroup;
            }
          })
          .then((resData) => {
            if (resData) {
              toast("Configuration saved successfully!");
              dispatch(updateUnsavedConfigureSettings([]));
              return true;
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }, 100);
    });
  };

  return {
    updateAssignmentForPatientOrGroup,
    unsavedConfigureSettings,
    updateUnsavedConfigSettings,
  };
};

export default useUpdateAssignment;
