import "./GroupChooser.styles.scss";
import { Avatar, GroupsDropdown } from "components";
import React from "react";
import { useTranslation } from "react-i18next";
import { Group } from "types";

interface GroupChooserProps {
  currentGroup: Group;
  onChange: (group: any) => void;
}

const GroupChooser = ({ currentGroup, onChange }: GroupChooserProps) => {
  const { t } = useTranslation(["common"]);
  const formattedCurrentGroup = {
    value: currentGroup.uuid,
    label: `${currentGroup.name}`,
  };

  const customStyles = {
    option: (provided: any) => ({
      ...provided,
      padding: 15,
    }),
    control: () => ({
      width: "auto",
      display: "flex",
      cursor: "pointer",
    }),
    valueContainer: (provided: any) => ({
      ...provided,
      fontWeight: "bold",
    }),
    indicatorSeparator: () => ({
      display: "none",
    }),
  };

  return (
    <div className="group-chooser">
      <div className="group-icon-wrapper">
        <Avatar firstName={currentGroup?.name} />
      </div>
      <div className="group-dropdown-wrapper">
        <GroupsDropdown
          name="group-chooser"
          label={null}
          includeEmail={false}
          placeholder={t("groups.filter_by_group")}
          defaultGroup={currentGroup}
          onChange={onChange}
          value={formattedCurrentGroup}
          isClearable={false}
          styles={customStyles}
          defaultToText={true}
          className="group-chooser-select"
        />
      </div>
    </div>
  );
};

export default GroupChooser;
