import { PURGE } from "redux-persist";

const initialState = {
  notes: [],
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case "FETCH_NOTES":
      return {
        ...state,
        notes: [...payload],
      };
    case "ADD_NOTE":
      return {
        ...state,
        notes: [...state.notes, payload],
      };
    case "UPDATE_NOTE":
      return {
        ...state,
        notes: state.notes.map((note) =>
          note.uuid === payload.uuid
            ? {
                ...note,
                ...payload,
              }
            : note
        ),
      };
    case "DELETE_NOTE":
      return {
        ...state,
        notes: state.notes.filter((note) => note.uuid !== payload),
      };
    case PURGE:
      return initialState;
    default:
      return state;
  }
};
