export const fetchAssignmentsAction = (assignments) => {
  return {
    type: "FETCH_PATIENT_ASSIGNMENTS",
    payload: assignments,
  };
};

export const addPatientAssignmentAction = (assignment) => {
  return {
    type: "ADD_PATIENT_ASSIGNMENT",
    payload: assignment,
  };
};

export const deletePatientAssignmentAction = (assignment) => {
  return {
    type: "DELETE_PATIENT_ASSIGNMENT",
    payload: assignment,
  };
};

export const updateNotificationAction = (notification) => {
  return {
    type: "UPDATE_NOTIFICATION",
    payload: notification,
  };
};

export const updatePatientAssignment = (assignment) => {
  return {
    type: "UPDATE_PATIENT_ASSIGNMENT",
    payload: assignment,
  };
};

export const updateUnsavedReminderSettings = (settings) => {
  return {
    type: "UPDATE_UNSAVED_REMINDER_SETTINGS",
    payload: settings,
  };
};

export const updateUnsavedConfigureSettings = (settings) => {
  return {
    type: "UPDATE_UNSAVED_CONFIGURE_SETTINGS",
    payload: settings,
  };
};
