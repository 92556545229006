import React from "react";
import { Icon } from "components";
import Search from "assets/icons/magnifying-glass.svg";

interface SubmissionsTableSearchProps {
  data: any;
  onChange: (filteredData: any) => void;
  patientId?: string;
}

const SubmissionsTableSearch = ({
  data,
  onChange,
  patientId,
}: SubmissionsTableSearchProps) => {
  const handleChange = (e: { target: { value: any } }) => {
    searchSubmissions(e.target.value);
  };

  const filterableData = patientId
    ? data.filter(
        (submission: { userId: string }) => submission.userId === patientId
      )
    : data;

  const searchSubmissions = (searchInput: string) => {
    const filteredData = filterableData.filter(
      (value: { assignmentLabel: string }) => {
        return value.assignmentLabel
          .toLowerCase()
          .includes(searchInput.toLowerCase());
      }
    );

    return onChange(filteredData);
  };

  return (
    <div className="data-table__search-wrapper">
      <div className="data-table__search-icon">
        <Icon src={Search} />
      </div>
      <input
        className="data-table__search-field"
        onChange={handleChange}
        placeholder="Search..."
      />
    </div>
  );
};

export default SubmissionsTableSearch;
