import { PURGE } from "redux-persist";

const initialState = {
  resources: [],
  therapistResources: [],
  patientResources: [],
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case "FETCH_PUBLIC_LIBRARY":
      return {
        ...state,
        resources: [...payload],
      };
    case "FETCH_PATIENT_LIBRARY":
      return {
        ...state,
        patientResources: [...payload],
      };
    case "FETCH_THERAPIST_LIBRARY":
      return {
        ...state,
        therapistResources: [...payload],
      };
    case "ADD_THERAPIST_LIBRARY_RESOURCE":
      return {
        ...state,
        therapistResources: [...state.therapistResources, payload],
      };
    case "UPDATE_THERAPIST_LIBRARY_RESOURCE":
      return {
        ...state,
        therapistResources: state.therapistResources.map((therapistResource) =>
          therapistResource.uuid === payload.uuid
            ? {
                ...therapistResource,
                ...payload,
              }
            : therapistResource
        ),
      };
    case "DELETE_THERAPIST_LIBRARY_RESOURCE":
      return {
        ...state,
        therapistResources: state.therapistResources.filter(
          (resource) => resource !== payload
        ),
      };
    case PURGE:
      return initialState;
    default:
      return state;
  }
};
