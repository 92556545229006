import { useEffect } from "react";
import { RootStateOrAny, useSelector } from "react-redux";
import useFetchGroups from "./useFetchGroups";

interface UseGetGroupsProps {
  groupId?: string;
  slug?: string;
}

const useGetGroups = ({ groupId, slug }: UseGetGroupsProps) => {
  const { fetchGroups } = useFetchGroups();

  const groups = useSelector((state: RootStateOrAny) => state.groups.groups);

  useEffect(() => {
    fetchGroups();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const specificGroup =
    groups?.filter((t: { uuid: string }) => t.uuid === groupId)[0] ||
    groups?.filter((t: { slug: string }) => t.slug === slug)[0] ||
    null;

  return {
    groups: groups || [],
    specificGroup,
  };
};

export default useGetGroups;
