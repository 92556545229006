import ThoughtIcon from "assets/icons/waveform-path.svg";
import MoodIcon from "assets/icons/cloud-rainbow.svg";
import JournalIcon from "assets/icons/book-spells.svg";
import SafetyPlanIcon from "assets/icons/lifebuoy.svg";
import GratitudeJournalIcon from "assets/icons/book-heart.svg";
import SocraticQuestionsIcon from "assets/icons/map-marker-question.svg";
import CognitiveRestructuringIcon from "assets/icons/brain.svg";
import CognitiveTriangleIcon from "assets/icons/recycle.svg";
import GoalJournalIcon from "assets/icons/atlas.svg";
import WorryJournalIcon from "assets/icons/temperature-high.svg";
import UpcomingSessionIcon from "assets/icons/calendar-circle-user.svg";
import PostSessionIcon from "assets/icons/memo-pad.svg";
import SensesExerciseIcon from "assets/icons/5.svg";

export const APP_VERSION = "2.0.5";

export const BRAND_NAME = process.env.REACT_APP_BRAND_NAME;
export const GA4 = process.env.REACT_APP_GA4;

export const ASSIGNMENT_TYPES = [
  {
    type: "THOUGHT_RECORD",
    title: "Thought Record",
    icon: ThoughtIcon,
  },
  {
    type: "SAFETY_PLAN",
    title: "Safety Plan",
    icon: SafetyPlanIcon,
  },
  {
    type: "MOOD_TRACKER",
    title: "Mood Tracker",
    icon: MoodIcon,
  },
  {
    type: "JOURNAL",
    title: "Journal",
    icon: JournalIcon,
  },
  {
    type: "GRATITUDE_JOURNAL",
    title: "Gratitude Journal",
    icon: GratitudeJournalIcon,
  },
  {
    type: "GOAL_JOURNAL",
    title: "Goal Journal",
    icon: GoalJournalIcon,
  },
  {
    type: "WORRY_JOURNAL",
    title: "Worry Journal",
    icon: WorryJournalIcon,
  },
  {
    type: "SOCRATIC_QUESTIONS",
    title: "Socratic Questions",
    icon: SocraticQuestionsIcon,
  },
  {
    type: "COGNITIVE_RESTRUCTURING",
    title: "Cognitive Restructuring",
    icon: CognitiveRestructuringIcon,
  },
  {
    type: "COGNITIVE_TRIANGLE",
    title: "The Cognitive Triangle",
    icon: CognitiveTriangleIcon,
  },
  {
    type: "UPCOMING_SESSION",
    title: "Upcoming Session Notes",
    icon: UpcomingSessionIcon,
  },
  {
    type: "POST_SESSION",
    title: "Post Session Notes",
    icon: PostSessionIcon,
  },
  {
    type: "SENSES_EXERCISE",
    title: "5-4-3-2-1 Exercise",
    icon: SensesExerciseIcon,
  },
];

export const BREAKPOINTS = {
  viewport4: "480",
  viewport7: "768",
  viewport9: "992",
  viewport12: "1200",
};

export const THOUGHT_DISTORTIONS = [
  {
    label: "Dichotomous Thinking",
    value: "Dichotomous Thinking",
    explanation: "All or nothing thinking",
    example: "I ate an extra snack, so I blew my whole diet.",
  },
  {
    label: "Catastrophizing",
    value: "Catastrophizing",
    explanation: "Predicting the future in negative terms",
    example: "I won't be able to do it and I can't handle that.",
  },
  {
    label: "Discounting The Positive",
    value: "Discounting The Positive",
    explanation: "The disqualification of positive experiences/events",
    example:
      "I was only successful because I got lucky. That burger I made was good, but anyone could make it. ",
  },
  {
    label: "Emotional Reasoning",
    value: "Emotional Reasoning",
    explanation:
      "The belief that emotions are a reflection of reality, which impacts my decision making and judgement",
    example: "I am scared of public bathrooms, which means they are dangerous.",
  },
  {
    label: "Labeling",
    value: "Labeling",
    explanation:
      "A fixed determination, which tends to be negative, on yourself and/or others.",
    example: "I am an idiot. ",
  },
  {
    label: "Magnification / Minimization",
    explanation:
      "Amplifying the negative outcomes, while minimizing the positive outcomes.",
    example:
      "I dropped my book bag in front of the whole school, which shows how much of a loser I am. I was picked as captain of my team, but that doesn't mean I am a leader.",
  },
  {
    label: "Selective Abstraction",
    value: "Selective Abstraction",
    explanation:
      "Missing the big picture and getting lost in the details - Getting lost in the weeds",
    example:
      "My teacher told me my paper was great, but because there is so much red marker, he most not really have liked it.",
  },
  {
    label: "Mind Reading",
    value: "Mind Reading",
    explanation:
      "The assumption that you know the thoughts or intentions of someone else",
    example: "They think I am ugly (without anyone ever saying it to you)",
  },
  {
    label: "Overgeneralization",
    value: "Overgeneralization",
    explanation:
      "Anecdotal information leads you to apply a general rule more widely than it should be",
    example: "Every time I leave a dish in the sink you yell at me.",
  },
  {
    label: "Personalizing",
    value: "Personalizing",
    explanation:
      "The assumption that another person's actions or an external event are directed towards me without considering that there could be another explination.",
    example:
      "That person was disrespectful because they didn't wave back to me (without considering that they may not have seen you wave to them)",
  },
  {
    label: "Should Statements",
    value: "Should Statements",
    explanation:
      "The belief that a certain event, someone's attitude, a certain behavior should be what I expect as opposed to something different.",
    example: "That person should have dated me instead of my friend.",
  },
  {
    label: "Jumping To Conclusions",
    value: "Jumping To Conclusions",
    explanation: "Drawing conclusions with little or no evidence",
    example: "They way she looked at me, I knew she thought it was my fault.",
  },
  {
    label: "Blaming",
    value: "Blaming",
    explanation:
      "One's attention is focused on others as the source of a negative emotion, rather than looking internally. Conversely, blaming could also take the form of taking personal blame when it is not ones fault",
    example:
      "My parents are the reason I can never be happy. It is my fault that I got layed off during COVID even though 80% of my team got layed off as well.",
  },
  {
    label: "What If?",
    value: "What If?",
    explanation:
      "Continued, ungrounded worry or sadness that something bad could happen.",
    example: "What if I get fired?",
  },
  {
    label: "Unfair Comparisons",
    value: "Unfair Comparisons",
    explanation:
      "Comparison between oneself and another, which leads the individual to feel 'less than' or in a disadvantageous spot.",
    example:
      "I am a failure because my friend makes so much more money than I do. ",
  },
];

export const NEGATIVE_EMOTIONS = [
  {
    label: "Anger",
    value: "Anger",
  },
  {
    label: "Anxiety",
    value: "Anxiety",
  },
  {
    label: "Disgust",
    value: "Disgust",
  },
  {
    label: "Envy",
    value: "Envy",
  },
  {
    label: "Fear",
    value: "Fear",
  },
  {
    label: "Guilt",
    value: "Guilt",
  },
  {
    label: "Jealousy",
    value: "Jealousy",
  },
  {
    label: "Love",
    value: "Love",
  },
  {
    label: "Sadness",
    value: "Sadness",
  },
  {
    label: "Shame",
    value: "Shame",
  },
];

export const EMOTIONS = [
  {
    label: "Unfair Comparisons",
    value: "Unfair Comparisons",
    explanation:
      "Comparison between oneself and another, which leads the individual to feel 'less than' or in a disadvantageous spot.",
    example:
      "I am a failure because my friend makes so much more money than I do. ",
  },
];

export const ASSIGNMENT_STATUSES = {
  VISIBLE: "VISIBLE",
  DELETED: "DELETED",
};

export const SAFETY_PLAN_STATUSES = {
  VISIBLE: "VISIBLE",
  DELETED: "DISABLED",
};

export const ASSIGNMENT_ENTITY_STATUSES = {
  VISIBLE: "VISIBLE",
  DISABLED: "DISABLED",
};

export const AUTO_SAVE_CONFIG = {
  INTERVAL: 3000,
};

export const FIELD_VALUES = {
  SKIPPED: "skipped",
};
