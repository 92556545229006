export const fetchPublicFilesAction = (files) => {
  return {
    type: "FETCH_PUBLIC_FILES",
    payload: files,
  };
};

export const fetchTherapistFilesAction = (files) => {
  return {
    type: "FETCH_THERAPIST_FILES",
    payload: files,
  };
};

export const fetchPatientFilesAction = (files) => {
  return {
    type: "FETCH_PATIENT_FILES",
    payload: files,
  };
};

export const fetchFileAttachmentAction = (file) => {
  return {
    type: "FETCH_FILE_ATTACHMENT",
    payload: file,
  };
};

export const addFileAction = (file) => {
  return {
    type: "ADD_THERAPIST_FILE",
    payload: file,
  };
};

export const updateFile = (file) => {
  return {
    type: "UPDATE_THERAPIST_FILE",
    payload: file,
  };
};

export const deleteFileAction = (fileId) => {
  return {
    type: "DELETE_THERAPIST_FILE",
    payload: fileId,
  };
};
